import { isNumber, isObject } from 'lodash-es';
import Model from './Model.js';
import AutomationCriterion from './AutomationCriterion.js';

export default class AutomationCriteriaLevel extends Model {
    static get defaults() {
        return {
            criteria: [],
            type: 'and',
        };
    }

    get criteria() {
        return this._criteria;
    }

    set criteria(criteria) {
        this._criteria = criteria.map(item => {
            if (item.criteria || item._criteria) {
                return new AutomationCriteriaLevel(item);
            }

            if (isNumber(item)) {
                return item;
            }

            return new AutomationCriterion(item);
        });
    }

    pushSubLevel() {
        this.criteria.push(
            new AutomationCriteriaLevel({
                type: 'and',
                criteria: [new AutomationCriterion()],
            }),
        );
    }

    pushCriterion() {
        this.criteria.push(new AutomationCriterion());
    }

    parseCriteria(criteria) {
        this.criteria = this.criteria.map(criterion => {
            if (criterion.criteria) {
                criterion.parseCriteria(criteria);
                return criterion;
            }

            return this.parseCriterion(criterion, criteria);
        });
    }

    parseCriterion(criterion, criteria) {
        if (isObject(criterion)) {
            return new AutomationCriterion(criterion);
        }

        return criteria.find(c => c.id == criterion);
    }

    toJSON() {
        return {
            type: this.type,
            criteria: this.criteria.map(criterion => (isNumber(criterion) ? criterion : criterion.toJSON())),
        };
    }
}
