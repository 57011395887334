import { uniqueId } from 'lodash-es';
import Model from './Model.js';
import { useContextStore } from '../store/modules/context/store.js';
import i18n from '../plugins/vue-i18n.js';

export default class AutomationCriterion extends Model {
    static get defaults() {
        return {
            property: '',
            operator: null,
            value: [],
            valid: true,
            key: uniqueId(),
        };
    }

    get filled() {
        let firstValue = this.firstValue;

        if (typeof firstValue == 'string') {
            firstValue = firstValue.trim();
        }

        return !!(this.operator && this.property && (firstValue || firstValue === 0));
    }

    get firstValue() {
        const [firstValue] = this.value;
        return firstValue || '';
    }

    get splitModelAndField() {
        // Split on the first dot or first @. If @ is first keep the @ in the field part (right side)
        const parts = this.property.match(/([^[.@]*)\.?(@?[^.].*)/) || [];
        return [parts[1] || '', parts[2] || ''];
    }

    get label() {
        const [model, field] = this.splitModelAndField;

        if (this.property.includes('customFields')) {
            return useContextStore().account.getCustomFieldById(this.property.match(/([0-9]*)]/)[1]).name;
        }

        return i18n.t(`automations.execution.fields.${model}.${field}`);
    }

    toJSON() {
        return {
            id: this.id,
            property: this.property,
            operator: this.operator,
            value: this.value,
            valid: this.valid,
        };
    }
}
