<template>
    <div>
        <portal to="content-header-right">
            <div class="flex items-center pt-1">
                <activix-tooltip
                    :disabled="!automation.retroactionInProgress"
                    :content="$t('automations.affectedLeadTooltip')"
                >
                    <span
                        class="pr-3"
                        :class="{
                            'loading-dots': automation.retroactionInProgress,
                            'loading-dots-ended': automation.retroactionCompleted,
                        }"
                        v-if="editMode"
                    >
                        {{ affectedLeadMessage }}
                    </span>
                </activix-tooltip>

                <icon
                    name="regular/delete-1"
                    class="link-grey-light mr-3 | hover:text-red-500"
                    @click="cancelValidation"
                    v-if="automation.retroactionInProgress"
                />

                <activix-tooltip :content="activeOptionTooltip" :disabled="!activeOptionTooltip">
                    <activix-switcher
                        :value="automation.active"
                        :disabled="
                            !automation.atLeastOneActionActive ||
                                automation.retroactionInProgress ||
                                !contextAccount.automation
                        "
                        @input="toggleActive($event)"
                    />
                </activix-tooltip>
            </div>
        </portal>

        <activix-tooltip
            :content="tooltipDisabledBy"
            :disabled="!automation.retroactionInProgress && !automation.system_type_id"
        >
            <div
                class="automation-wrapper"
                :class="{ loading: automation.retroactionInProgress || automation.system_type_id }"
            >
                <automation-config
                    class="mb-6"
                    :class="{ loading: $wait.is('fetching.automation') }"
                    :automation="automation"
                    @update:is-valid="configIsValid = $event"
                />

                <automation-criteria
                    class="mb-6"
                    :class="{ loading: !configIsValid || $wait.is('fetching.automation') }"
                    :automation="automation"
                    @update:is-valid="criteriaAreValid = $event"
                />

                <automation-actions
                    class="mb-6"
                    :class="{ loading: !configIsValid || $wait.is('fetching.automation') }"
                    :automation="automation"
                    @update:is-valid="actionsAreValid = $event"
                />

                <automation-buttons
                    class="text-right"
                    :loading="$wait.is('fetching.automation')"
                    :saving="$wait.is(['creating.automation', 'updating.automation'])"
                    :is-valid="isValid"
                    @save="beforeSave"
                />
            </div>
        </activix-tooltip>

        <activix-confirm-modal
            type="warning"
            :content="$t('automations.retroactionModal')"
            :opened.sync="retroactionModal.opened"
            @approve="onSaveWithRetroaction"
            @deny="onSave"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('automations.deactivateModal')"
            :opened.sync="deactivateModal.opened"
            @approve="onSaveWithDeactivate()"
            @deny="onSave"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('automations.disableAutomation')"
            :content="$t('automations.deactivateAutomationModal')"
            :opened.sync="disableAutomationWarningModal"
            @approve="toggleAutomationIsActive()"
        />
    </div>
</template>

<script>
    import { clone, get } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';
    import AutomationActions from '../../components/automations/AutomationActions.vue';
    import AutomationButtons from '../../components/automations/AutomationButtons.vue';
    import AutomationConfig from '../../components/automations/AutomationConfig.vue';
    import AutomationCriteria from '../../components/automations/AutomationCriteria.vue';

    import Automation from '../../entities/Automation.js';
    import TrackView from '../../mixins/TrackView.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'AutomationsEdit',

        components: {
            AutomationActions,
            AutomationButtons,
            AutomationConfig,
            AutomationCriteria,
        },

        mixins: [TrackView],

        provide() {
            return {
                setAutomationData: this.setAutomationData,
                setAutomationAction: this.setAutomationAction,
            };
        },

        data() {
            return {
                actionsAreValid: false,
                automation: new Automation(),
                configIsValid: false,
                criteriaAreValid: false,
                deactivateModal: {
                    opened: false,
                    deactivate: false,
                },
                disableAutomationWarningModal: false,
                init: false,
                retroactionModal: {
                    opened: false,
                    retroaction: false,
                },
                wasActive: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            tooltipDisabledBy() {
                return this.automation.system_type_id
                    ? this.$t('automations.disabledBySystemAutomation')
                    : this.$t('automations.disabledByRetroactionInProgress');
            },

            editMode() {
                return !!this.automationId;
            },

            activeOptionTooltip() {
                if (!this.contextAccount.automation) {
                    return this.$t('automations.automationMustBeActiveInAccount');
                }

                if (!this.automation.atLeastOneActionActive) {
                    return this.$t('automations.activeDisabledByNoActiveAction');
                }

                return '';
            },

            automationId() {
                if (this.$route.name != 'automations.update') {
                    return null;
                }

                return this.$route.params.id;
            },

            actions() {
                return this.automation.actions.map(action => {
                    const execution = {
                        recurrence: action.execution.recurrence,
                        recurrence_direction: action.execution.recurrenceDirection,
                        occurence: action.execution.occurence,
                        delay_amount: action.execution.delayAmount,
                        delay_direction: action.execution.delayDirection,
                        delay_unit: action.execution.delayUnit,
                        type_of_time: action.execution.typeOfTime,
                        time_of_day: action.execution.timeOfDay,
                        field: action.execution.field,
                        schedule_aware: action.execution.scheduleAware,
                    };

                    return {
                        id: action.id,
                        action: action.action,
                        active: action.active,
                        parameters: action.parameters,
                        execution,
                    };
                });
            },

            isValid() {
                return this.configIsValid && this.criteriaAreValid && this.actionsAreValid;
            },

            automationPayload() {
                return {
                    account_id: this.contextAccount.id,
                    actions: this.actions,
                    active: this.automation.active,
                    criteria_structure: this.automation.criteria_structure,
                    event: this.automation.event || null,
                    model: this.automation.model || null,
                    name: this.automation.name,
                    hasRetroactive: this.retroactionModal.retroaction,
                    deletePendingAutomation: this.deactivateModal.deactivate,
                    visible: this.automation.visible,
                    trigger_field: this.automation.trigger_field || null,
                };
            },

            affectedLeadMessage() {
                return this.$tc('automations.table.row.affectedClients', this.automation.affected_models, [
                    this.automation.affected_models,
                ]);
            },
        },

        watch: {
            automationId: {
                immediate: true,
                handler() {
                    this.fetchAutomation();
                },
            },

            'automation.hasInvalidCriterion': {
                immediate: true,
                handler() {
                    this.syncActiveState();
                },
            },

            'automation.atLeastOneActionActive': {
                immediate: true,
                handler() {
                    this.syncActiveState();
                },
            },

            'automation.retroaction_execution_start_at': {
                immediate: true,
                handler() {
                    this.syncActiveState();
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction']),

            toggleActive(automationState) {
                if (automationState) {
                    this.toggleAutomationIsActive();
                    return;
                }

                this.disableAutomationWarningModal = true;
            },

            toggleAutomationIsActive() {
                this.automation.active = !this.automation.active;
            },

            syncActiveState() {
                if (this.init) {
                    this.automation.active =
                        !this.automation.hasInvalidCriterion &&
                        this.automation.atLeastOneActionActive &&
                        this.contextAccount.automation;
                }
            },

            beforeSave(returnToList = false) {
                if (this.authUser.automation_advanced_access && this.automation.hasExecutionField) {
                    // Only show for advance user
                    if (
                        this.automation.active &&
                        this.automation.hasExecutionField &&
                        this.automation.atLeastOneActionActive &&
                        !this.automation.trigger_field &&
                        this.automation.affected_models == 0
                    ) {
                        this.deactivateModal.retroaction = false;
                        this.retroactionModal.opened = true;

                        return;
                    }

                    if (!this.automation.active && this.wasActive && this.automation.affected_models != 0) {
                        this.deactivateModal.deactivate = false;
                        this.deactivateModal.opened = true;

                        return;
                    }
                }

                this.onSave(returnToList);
            },

            onSaveWithDeactivate() {
                this.deactivateModal.deactivate = true;
                this.retroactionModal.retroaction = false;

                this.onSave();
            },

            onSaveWithRetroaction() {
                this.retroactionModal.retroaction = true;
                this.deactivateModal.deactivate = false;

                this.onSave();
            },

            onSave(returnToList) {
                if (this.automationId) {
                    this.updateAutomation(returnToList);
                } else {
                    this.createAutomation(returnToList);
                }
            },

            setAutomationData(data) {
                Object.assign(this.automation, data);
            },

            setAutomationAction(index, action) {
                const actions = clone(this.automation.actions);

                if (action) {
                    actions[index] = action;
                } else {
                    actions.splice(index, 1);
                }

                this.setAutomationData({ actions });
            },

            listenToAccount() {
                if (!this.$broadcasting.channels.account) {
                    return;
                }

                this.$broadcasting.channels.account.listen('Automation\\AutomationRetroactionChangeState', data => {
                    if (this.automation.id == data.automationId) {
                        this.automation.retroaction_execution_start_at = data.startAt;
                        this.automation.retroaction_execution_end_at = data.endAt;
                    }
                });
            },

            listenToUser() {
                if (!this.$broadcasting.channels.user) {
                    return;
                }

                this.$broadcasting.channels.user.listen('Automation\\UpdateAffectedModels', data => {
                    if (this.automation.id == data.automationId) {
                        this.automation.affected_models = data.affectedModels;
                    }
                });
            },

            async fetchAutomation() {
                this.automation.id = this.automationId;

                if (!this.automation.id) {
                    return;
                }

                this.$wait.start('fetching.automation');

                try {
                    const response = await this.$api.automations.show(this.automationId);
                    this.automation = new Automation(response);
                    this.automation.parseStructure();

                    if (this.automation.active) {
                        this.wasActive = true;
                    }

                    this.setContextAccountAction(this.automation.account_id);

                    this.$wait.end('fetching.automation');
                } catch (error) {
                    this.$wait.end('fetching.automation');

                    if (get(error.response, 'status') !== 404) {
                        this.$notify.error(this.$t('automations.alerts.show.error'));
                    }

                    this.$nextTick(() => {
                        this.$router.replace({ name: 'automations.index' });
                    });

                    throw error;
                }
            },

            async createAutomation(returnToList) {
                this.$wait.start('creating.automation');

                try {
                    const automation = await this.$api.automations.store(this.automationPayload);

                    this.$wait.end('creating.automation');

                    this.$notify.success(this.$t('automations.alerts.store.success'));

                    if (returnToList) {
                        this.$router.push({ name: 'automations.index' });
                    } else {
                        this.$router.replace({ name: 'automations.update', params: { id: automation.id } });
                    }
                } catch (error) {
                    this.deactivateModal.deactivate = false;
                    this.retroactionModal.retroaction = false;

                    this.$wait.end('creating.automation');
                    this.$notify.error(this.$t('automations.alerts.store.error'));

                    throw error;
                }
            },

            async updateAutomation(returnToList) {
                this.$wait.start('updating.automation');

                try {
                    await this.$api.automations.update(this.automation.id, this.automationPayload);

                    this.$wait.end('updating.automation');

                    this.$notify.success(this.$t('automations.alerts.update.success'));

                    if (returnToList) {
                        this.$router.push({ name: 'automations.index' });
                    }
                } catch (error) {
                    this.deactivateModal.deactivate = false;
                    this.retroactionModal.retroaction = false;

                    this.$wait.end('updating.automation');
                    this.$notify.error(this.$t('automations.alerts.update.error'));

                    throw error;
                }
            },

            async cancelValidation() {
                this.$wait.start('updating.automation');

                try {
                    const response = await this.$api.automations.update(this.automation.id, { cancelValidation: true });

                    this.automation.retroaction_execution_end_at = response.retroaction_execution_end_at;
                    this.automation.retroaction_execution_start_at = response.retroaction_execution_start_at;

                    this.$wait.end('updating.automation');
                } catch (error) {
                    this.$wait.end('updating.automation');
                    this.$notify.error(this.$t('automations.alerts.update.error'));

                    throw error;
                }
            },
        },

        created() {
            this.listenToAccount();
            this.listenToUser();

            this.$broadcasting.$on('account-channel-ready', this.listenToAccount);
            this.$broadcasting.$on('user-channel-ready', this.listenToUser);

            setTimeout(() => {
                this.init = true;
            }, 5000);
        },

        beforeDestroy() {
            this.$broadcasting.$off('account-channel-ready', this.listenToAccount);
            this.$broadcasting.$off('user-channel-ready', this.listenToUser);
        },

        beforeCreate() {
            this.init = false;
        },
    };
</script>
