<template>
    <div class="flex">
        <div class="w-full lg:w-1/4 pr-4">
            <div class="mt-4">
                <div class="flex items-center">
                    <activix-multiselect
                        white
                        :disabled="locked || $wait.is('fetching.niotextTemplates')"
                        :allow-empty="false"
                        :multiple="false"
                        :options="templateCategories"
                        :searchable="false"
                        :selected="selectedCategory"
                        :placeholder="$t('automations.actions.sendSms.category')"
                        label="name"
                        key="id"
                        @update="updateSelectedCategory"
                    />
                    <div class="flex items-center">
                        <button class="flex link-grey text-sm ml-3" @click="reloadTemplates">
                            <icon
                                :name="$icons.loading"
                                :class="{ 'spin-inverse': $wait.is('fetching.niotextTemplates') }"
                            />
                        </button>
                        <a
                            class="flex link-grey text-sm ml-2"
                            target="_blank"
                            :href="nioTextUrl"
                            v-if="hasAccessToNioText"
                        >
                            <icon name="bold/add" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <activix-multiselect
                    white
                    :allow-empty="false"
                    :disabled="locked || !selectedCategory || $wait.is('fetching.niotextTemplates')"
                    :options="templates"
                    :selected="selectedTemplate"
                    class="flex-1"
                    :placeholder="$t('automations.actions.sendSms.template')"
                    label="name"
                    key="id"
                    @update="updateSelectedTemplate"
                />
            </div>

            <div class="mt-4">
                <activix-multiselect
                    identifier="key"
                    label="label"
                    white
                    :disabled="locked"
                    :allow-empty="false"
                    :multiple="false"
                    :options="roles"
                    :searchable="false"
                    :placeholder="$t('automations.actions.sendSms.userToUse')"
                    :selected="selectedRole"
                    @update="updateSelectedRole"
                />
            </div>

            <div class="mt-4" v-if="selectedRole">
                <activix-multiselect
                    identifier="key"
                    label="label"
                    white
                    :disabled="locked"
                    :allow-empty="false"
                    :multiple="false"
                    :options="nioTextUsers"
                    :searchable="true"
                    :placeholder="$t('automations.actions.sendSms.fallback')"
                    :selected="selectedFallbackUser"
                    @update="updateFallbackUser"
                />
            </div>

            <div class="mt-4" v-if="selectedRole">
                <activix-multiselect
                    identifier="key"
                    label="label"
                    white
                    :disabled="locked"
                    :allow-empty="false"
                    :multiple="false"
                    :options="formattedActiveUsers"
                    :searchable="true"
                    :tooltip="$t('automations.actions.sendSms.assignToTooltip')"
                    :placeholder="$t('automations.actions.sendSms.assignTo')"
                    :selected="selectedAssignedUser"
                    @update="updateAssignTo"
                />
            </div>

            <hr />

            <div class="flex items-center mt-4">
                <activix-checkbox
                    size="sm"
                    :value="action.parameters.use_lead_locale"
                    :disabled="locked"
                    @input="updateUseLeadLocale"
                >
                    <span class="font-normal" v-text="$t('automations.actions.sendSms.useLeadLocale')" />
                </activix-checkbox>
                <activix-tooltip :content="$t('automations.actions.sendSms.useLeadLocaleTooltip')">
                    <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                </activix-tooltip>
            </div>

            <template v-if="!action.parameters.use_lead_locale">
                <div class="mt-4">
                    <label class="flex items-center">
                        {{ $t('automations.actions.sendSms.localePreference') }}
                        <activix-tooltip :content="$t('automations.actions.sendSms.localePreferenceTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </label>
                    <activix-multiselect
                        identifier="key"
                        label="label"
                        white
                        :disabled="locked"
                        :allow-empty="false"
                        :multiple="false"
                        :options="localeOptions"
                        :searchable="false"
                        :selected="selectedLocaleOption"
                        @update="updateSelectedLocale"
                    />
                </div>
            </template>

            <div class="flex items-center mt-4">
                <activix-checkbox
                    size="sm"
                    :disabled="locked"
                    :value="action.parameters.affect_lead_status"
                    @input="updateAffectLeadStatus"
                >
                    <span class="font-normal" v-text="$t('automations.actions.sendSms.affectLeadResult')" />
                </activix-checkbox>
                <activix-tooltip :content="$t('automations.actions.sendSms.affectLeadResultTooltip')">
                    <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                </activix-tooltip>
            </div>
        </div>

        <div class="flex flex-col w-full lg:w-3/4 mt-4 mr-4">
            <div class="panel panel-default | flex-grow mb-0">
                <div class="panel-heading flex justify-between">
                    <span v-html="selectedTemplate ? selectedTemplate.name : ''" />
                    <a @click="previewLocale = previewLocale == 'en' ? 'fr' : 'en'">
                        {{ previewLocale == 'en' ? 'FR' : 'EN' }}
                    </a>
                </div>
                <div class="panel-body">
                    <span class="whitespace-pre-line" v-html="messagePreview" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { cloneDeep, get, orderBy, has } from 'lodash-es';
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../../store/store.js';
    import { useContextStore } from '../../../store/modules/context/store.js';

    export default {
        name: 'SendSms',

        props: {
            action: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                templateCategories: [],
                previewLocale: this.$i18n.locale,
                roles: [
                    {
                        key: 'user',
                        label: this.$t('automations.leadRelatedUser.user'),
                    },
                    {
                        key: 'bdcUser',
                        label: this.$t('automations.leadRelatedUser.bdcUser'),
                    },
                    {
                        key: 'serviceAdvisorUser',
                        label: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                    },
                    {
                        key: 'serviceAgentUser',
                        label: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                    },
                    {
                        key: 'commercial',
                        label: this.$t('automations.leadRelatedUser.commercial'),
                    },
                    {
                        key: 'custom',
                        label: this.$t('automations.leadRelatedUser.custom'),
                    },
                ],
                localeOptions: [
                    {
                        key: 'fr',
                        label: 'FR',
                    },
                    {
                        key: 'en',
                        label: 'EN',
                    },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers', 'parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            locked() {
                return !this.contextAccount.hasNioText() || !this.authUser.automation_advanced_access;
            },

            selectedLocaleOption() {
                return this.localeOptions.find(locale => locale.key == this.action.parameters.locale);
            },

            selectedRole() {
                return this.roles.find(role => role.key == this.action.parameters.from_role);
            },

            selectedFallbackUser() {
                return this.nioTextUsers.find(role => role.key == this.action.parameters.fallback_user);
            },

            selectedAssignedUser() {
                return this.formattedActiveUsers.find(role => role.key == this.action.parameters.assign_to);
            },

            selectedCategory() {
                return this.templateCategories.find(category => category.id == this.action.parameters.template.category_id);
            },

            selectedTemplate() {
                if (!this.selectedCategory) {
                    return null;
                }

                return this.selectedCategory.templates.find(template => template.id == this.action.parameters.template.id);
            },

            nioTextUsers() {
                return this.activeUsers.filter(user => user.hasNioText()).map(user => ({
                    key: user.id,
                    label: user.fullName,
                }));
            },

            formattedActiveUsers() {
                return this.activeUsers.map(user => ({
                    key: user.id,
                    label: user.fullName,
                }));
            },

            templates() {
                if (!this.selectedCategory) {
                    return [];
                }

                return orderBy(this.selectedCategory.templates, ['name']);
            },

            isValid() {
                return (
                    !!this.selectedCategory && !!this.selectedTemplate && !!this.selectedRole && (this.action.parameters.use_lead_locale || !!this.selectedLocaleOption) && this.action.execution.isValid()
                );
            },

            messagePreview() {
                return this.selectedTemplate ? this.selectedTemplate.content[this.previewLocale] : this.$t('templates.previewMessage', [], 'fr');
            },

            nioTextUrl() {
                return `${process.env.VUE_APP_NIOTEXT_URL}/configs/organization/templates?token=${this.parentAuthUser.niotext_token}`;
            },

            hasAccessToNioText() {
                return this.parentAuthUser.hasNioText() || this.parentAuthUser.children.some(user => user.hasNioText());
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },

            'action'(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },
        },

        methods: {
            getExcerpt(value) {
                return {
                    fr: value.fr.substring(0, 255),
                    en: value.en.substring(0, 255),
                };
            },

            updateSelectedCategory(value) {
                const template = {
                    id: null,
                    category_id: value?.id,
                };

                this.updateAction('parameters', { template });
            },

            updateSelectedTemplate(value) {
                const template = {
                    id: value?.id,
                    category_id: this.selectedCategory?.id,
                };

                this.updateAction('parameters', { template });
            },

            updateSelectedRole(value) {
                this.updateAction('parameters', { from_role: get(value, 'key', null) });
            },

            updateSelectedLocale(value) {
                this.updateAction('parameters', { locale: get(value, 'key', null) });
            },

            updateUseLeadLocale(value) {
                this.updateAction('parameters', { use_lead_locale: value });
            },

            updateFallbackUser(value) {
                this.updateAction('parameters', { fallback_user: get(value, 'key', null) });
            },

            updateAssignTo(value) {
                this.updateAction('parameters', { assign_to: get(value, 'key', null) });
            },

            updateAffectLeadStatus(value) {
                this.updateAction('parameters', { affect_lead_status: value });
            },

            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },

            reloadTemplates() {
                this.loadTemplates(true);
            },

            async loadTemplates(bustCache = false) {
                this.$wait.start('fetching.niotextTemplates');
                this.templateCategories = await this.$api.niotextTemplates.index(this.contextAccount.id, bustCache);
                this.$wait.end('fetching.niotextTemplates');
            },
        },

        mounted() {
            this.loadTemplates();
        },
    };
</script>
