import Enum from './Enum.js';
import i18n from '../plugins/vue-i18n.js';
import { capitalizeFirst } from '../utils/string.js';

export default class AutomationDelayUnit extends Enum {
    static get entries() {
        return {
            minutes: 'minutes',
            hours: 'hours',
            days: 'days',
            weeks: 'weeks',
            months: 'months',
            years: 'years',
        };
    }

    static get MINUTES() {
        return this.entries.minutes;
    }

    static get HOURS() {
        return this.entries.hours;
    }

    static get DAYS() {
        return this.entries.DAYS;
    }

    static get WEEKS() {
        return this.entries.weeks;
    }

    static get MONTHS() {
        return this.entries.months;
    }

    static get YEARS() {
        return this.entries.years;
    }

    static get translationGroup() {
        return 'delayUnits';
    }

    static getTranslation(value) {
        const key = this.getKey(value);

        if (!key) {
            return '';
        }

        return capitalizeFirst(i18n.tc(`${this.translationGroup}.${key}`, 2));
    }

    static selectOptions(idName = '', labelName = '') {
        return this.values.map(value => {
            return {
                [idName || 'id']: value,
                [labelName || 'label']: this.getTranslation(value),
            };
        });
    }
}
