import { isEmpty } from 'lodash-es';
import Enum from './Enum.js';
import i18n from '../plugins/vue-i18n.js';

export default class AutomationRecurrenceType extends Enum {
    static get entries() {
        return {
            now: 'now',
            daily: 'daily',
            weekly: 'weekly',
            monthly: 'monthly',
            yearly: 'yearly',
        };
    }

    static get NOW() {
        return this.entries.now;
    }

    static get DAILY() {
        return this.entries.daily;
    }

    static get WEEKLY() {
        return this.entries.weekly;
    }

    static get MONTHLY() {
        return this.entries.monthly;
    }

    static get YEARLY() {
        return this.entries.yearly;
    }

    static get translationGroup() {
        return 'recurrenceTypes';
    }

    static getTranslation(value, customKey = false) {
        let key = this.getKey(value);

        if (customKey) {
            key = value;
        }

        if (!key) {
            return '';
        }

        return i18n.t(`${this.translationGroup}.${key}`);
    }

    static selectOptions(idName = '', labelName = '', customOptions = []) {
        let options = this.values;

        if (!isEmpty(customOptions)) {
            options = customOptions;
        }

        return options.map(value => {
            let label = this.getTranslation(value);

            if (label == '') {
                label = this.getTranslation(value, true);
            }

            return {
                [idName || 'id']: value,
                [labelName || 'label']: label,
            };
        });
    }
}
