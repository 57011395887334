import { isEmpty } from 'lodash-es';
import Model from './Model.js';
import AutomationRecurrenceType from './AutomationRecurrenceType.js';
import AutomationRecurrenceDirection from './AutomationRecurrenceDirection.js';
import AutomationDelayDirection from './AutomationDelayDirection.js';
import AutomationDelayUnit from './AutomationDelayUnit.js';
import { useContextStore } from '../store/modules/context/store.js';
import i18n from '../plugins/vue-i18n.js';

export default class AutomationExecution extends Model {
    static TRIGGER_FIELD = 'trigger';

    static CUSTOM_TIME = 'custom';

    static FIELD_TIME = 'field';

    static get defaults() {
        return {
            recurrence: null,
            recurrenceDirection: null,
            occurence: 1,
            delayDirection: null,
            delayAmount: 0,
            delayUnit: null,
            field: null,
            typeOfTime: null,
            timeOfDay: null,
            scheduleAware: null,
        };
    }

    /**
     * Getters
     */

    get mandatoryProperties() {
        const fields = ['recurrence'];

        if (this.hasRecurrence()) {
            fields.push('recurrenceDirection');
            fields.push('occurence');
        }

        if (!this.hasRecurrence()) {
            fields.push('delayUnit');
        }

        if (this.delayUnit && this.delayUnit != 'none') {
            fields.push('delayAmount');
            fields.push('delayDirection');
        }

        if (
            (this.recurrence && this.recurrence !== 'none') ||
            (this.recurrence && this.recurrence === 'none' && this.delayUnit)
        ) {
            fields.push('field');
        }

        if (
            !this.hasRecurrence() &&
            this.hasDelay() &&
            this.delayUnit != AutomationDelayUnit.MINUTES &&
            this.delayUnit != AutomationDelayUnit.HOURS
        ) {
            fields.push('typeOfTime');
        }

        if (this.typeOfTime == AutomationExecution.CUSTOM_TIME) {
            fields.push('timeOfDay');
        }

        return fields;
    }

    get recurrence() {
        return this._recurrence;
    }

    get recurrenceDirection() {
        return this._recurrenceDirection;
    }

    get delayDirection() {
        return this._delayDirection;
    }

    get delayUnit() {
        return this._delayUnit;
    }

    get occurence() {
        return this._occurence;
    }

    get scheduleAware() {
        return this._scheduleAware;
    }

    get label() {
        if (this.field.includes('customFields')) {
            return useContextStore().account.getCustomFieldById(this.field.match(/([0-9]*)]/)[1]).name.toLowerCase();
        }

        return i18n.t(`automations.execution.fields.${this.field}`).toLowerCase();
    }

    /**
     * Setters
     */

    set recurrence(recurrence) {
        this._recurrence = recurrence;
    }

    set recurrenceDirection(direction) {
        this._recurrenceDirection = direction || AutomationRecurrenceDirection.FUTURE;
    }

    set delayDirection(direction) {
        this._delayDirection = direction || AutomationDelayDirection.AFTER;
    }

    set delayUnit(unit) {
        this._delayUnit = unit;
    }

    set occurence(occurence) {
        this._occurence = parseInt(occurence, 10);
    }

    set scheduleAware(scheduleAware) {
        this._scheduleAware = scheduleAware;
    }
    /**
     * Specification
     */

    isNow() {
        return this.recurrence == AutomationRecurrenceType.NOW;
    }

    isRecurring() {
        return this.recurrence && this.recurrence != AutomationRecurrenceType.NOW && this.occurence > 0;
    }

    hasRecurrence() {
        return this.recurrence && this.recurrence != AutomationRecurrenceType.NOW;
    }

    hasDelay() {
        return this.delayAmount > 0 && this.delayDirection && this.delayUnit && this.delayUnit !== 'none';
    }

    isValid() {
        const result = this.mandatoryProperties.some(field => {
            if (field != 'recurrence') {
                if (typeof this[field] == 'number') {
                    return this[field] < 1;
                }
                return isEmpty(this[field]);
            }

            return false;
        });

        return !result;
    }

    isMandatory(field) {
        return this.mandatoryProperties.includes(field);
    }
}
