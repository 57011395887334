import Enum from './Enum.js';
import { capitalizeFirst } from '../utils/string.js';
import i18n from '../plugins/vue-i18n.js';

export default class AutomationDelayDirection extends Enum {
    static get entries() {
        return {
            before: 'before',
            after: 'after',
        };
    }

    static get BEFORE() {
        return this.entries.before;
    }

    static get AFTER() {
        return this.entries.after;
    }

    static get translationGroup() {
        return 'delayDirections';
    }

    static getTranslation(value) {
        const key = this.getKey(value);

        if (!key) {
            return '';
        }

        return capitalizeFirst(i18n.t(`${this.translationGroup}.${key}`));
    }

    static selectOptions(idName = '', labelName = '') {
        return this.values.map(value => {
            return {
                [idName || 'id']: value,
                [labelName || 'label']: this.getTranslation(value),
            };
        });
    }
}
