import Enum from './Enum.js';

export default class AutomationState extends Enum {
    static get entries() {
        return {
            no_retroaction: 'no_retroaction',
            retroaction_completed: 'validation_completed',
            retroaction_in_progress: 'validation_in_progress',
        };
    }

    static get NO_RETROACTION() {
        return this.entries.no_retroaction;
    }

    static get RETROACTION_COMPLETED() {
        return this.entries.retroaction_completed;
    }

    static get RETROACTION_IN_PROGRESS() {
        return this.entries.retroaction_in_progress;
    }
}
