<template>
    <div>
        <label>
            {{ $t('automations.config.when') }}
            <span class="form-star" v-if="empty(selectedTrigger)">*</span>
        </label>

        <activix-multiselect
            identifier="key"
            label="label"
            :allow-empty="false"
            :options="triggerOptions"
            :searchable="false"
            :selected="selectedTrigger"
            :disabled="!authUser.automation_advanced_access && selectedTrigger && selectedTrigger.key == 'lead.update'"
            @update="updateTrigger"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */

    // Pinia
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        inject: ['setAutomationData'],

        props: {
            automation: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                filteredField: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            triggerOptions() {
                return this.configs.automatableModels.map(model => {
                    const disabled = model.includes('update') ? !this.authUser.automation_advanced_access : false;

                    return {
                        key: model,
                        label: this.$t(`automations.trigger.${model}`),
                        disabled,
                    };
                });
            },

            selectedTrigger() {
                return (
                    this.triggerOptions.find(
                        option => option.key == `${this.automation.model}.${this.automation.event}`,
                    ) || null
                );
            },

            isValid() {
                return !!(this.automation.model && this.automation.event);
            },
        },

        watch: {
            'automation.model'(newValue, oldValue) {
                if (oldValue != newValue && oldValue) {
                    this.automation.fields = [];
                }
            },

            'automation.event'(newValue, oldValue) {
                if (oldValue != newValue && oldValue) {
                    this.automation.fields = [];
                }
            },

            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('update:is-valid', newValue);
                },
            },
        },

        methods: {
            updateTrigger(selectedTrigger) {
                const [model, event] = selectedTrigger.key.split('.');
                this.setAutomationData({
                    model,
                    event,
                });
            },
        },
    };
</script>
