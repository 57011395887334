<template>
    <div>
        <activix-button :disabled="loading" @click="onCancel">
            {{ $t('general.cancel') }}
        </activix-button>
        <activix-tooltip class="ml-1" :content="invalidAutomationTooltip">
            <span>
                <activix-button
                    type="primary"
                    :disabled="loading || !isValid"
                    :loading="saving"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </activix-button>
            </span>
        </activix-tooltip>
        <activix-tooltip class="ml-1" :content="invalidAutomationTooltip">
            <span>
                <activix-button
                    type="primary"
                    :disabled="loading || !isValid"
                    :loading="saving"
                    @click="onSaveAndClose"
                >
                    {{ $t('general.saveAndClose') }}
                </activix-button>
            </span>
        </activix-tooltip>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            saving: {
                type: Boolean,
                default: false,
            },
            isValid: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            invalidAutomationTooltip() {
                return !this.isValid ? this.$t('automations.validation') : '';
            },
        },

        methods: {
            onCancel() {
                this.$router.push({ name: 'automations.index' });
            },

            onSave() {
                this.$emit('save');
            },

            onSaveAndClose() {
                this.$emit('save', true);
            },
        },
    };
</script>
