<template>
    <div>
        <label>
            {{ $t('automations.config.title') }}
            <span class="form-star" v-if="!automation.name">*</span>
        </label>

        <input
            class="form-control"
            type="text"
            :value="automation.name"
            ref="input"
            @input="updateName"
        />
    </div>
</template>

<script>
    import { debounce, upperFirst } from 'lodash-es';

    export default {
        name: 'AutomationName',

        inject: ['setAutomationData'],

        props: {
            automation: {
                type: Object,
                required: true,
            },
        },

        computed: {
            editMode() {
                return !!this.automation.id;
            },

            isValid() {
                return !!this.automation.name;
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('update:is-valid', newValue);
                },
            },
        },

        methods: {
            updateName(e) {
                this.setAutomationData({ name: upperFirst(e.target.value) });
            },
        },

        created() {
            if (!this.editMode && !this.isValid) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
            this.updateName = debounce(this.updateName, 200);
        },
    };
</script>
