<template>
    <div class="flex">
        <div class="w-full lg:w-1/4 pr-4">
            <label>
                {{ $t('automations.actions.sendConsentDoubleOptIn.sender') }}
                <span class="form-star">*</span>
            </label>
            <activix-multiselect
                identifier="key"
                label="label"
                white
                :allow-empty="false"
                :multiple="false"
                :options="roles"
                :searchable="false"
                :selected="selectedRole"
                @update="updateSelectedRole"
            />
        </div>

        <div class="w-full lg:w-1/4 mr-4" v-if="selectedRole">
            <user-selection
                :multiple="false"
                :filterable="false"
                :label="userSelectionLabel"
                :required="true"
                :value="action.parameters.fallback_user_id"
                :with-relative="false"
                @input="updateFallbackUser"
            />
        </div>
    </div>
</template>

<script>
    import { cloneDeep, get, has } from 'lodash-es';

    import UserSelection from '@/components/automations/actions/CreateTaskEvent/UserSelection';

    export default {
        components: { UserSelection },

        props: {
            action: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                roles: [
                    {
                        key: 'user',
                        label: this.$t('automations.leadRelatedUser.user'),
                    },
                    {
                        key: 'bdcUser',
                        label: this.$t('automations.leadRelatedUser.bdcUser'),
                    },
                    {
                        key: 'serviceAdvisorUser',
                        label: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                    },
                    {
                        key: 'serviceAgentUser',
                        label: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                    },
                    {
                        key: 'commercial',
                        label: this.$t('automations.leadRelatedUser.commercial'),
                    },
                    {
                        key: 'specificUser',
                        label: this.$t('automations.leadRelatedUser.specificUser'),
                    },
                ],
            };
        },

        computed: {
            isValid() {
                return (
                    !!this.selectedRole && this.action.parameters.fallback_user_id != null && this.action.execution.isValid()
                );
            },

            selectedRole() {
                return this.roles.find(role => role.key === this.action.parameters.from_role);
            },

            userSelectionLabel() {
                if (this.selectedRole?.key === 'specificUser') {
                    return this.$t('automations.actions.sendConsentDoubleOptIn.user');
                }

                return this.$t('automations.actions.sendConsentDoubleOptIn.fallbackSender');
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },

            'action'(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },
        },

        methods: {
            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },

            updateSelectedRole(value) {
                this.updateAction('parameters', { from_role: get(value, 'key', null) });
            },

            updateFallbackUser(value) {
                this.updateAction('parameters', { fallback_user_id: value });
            },
        },
    };
</script>
