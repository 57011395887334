import Enum from './Enum.js';

export default class RefinancedType extends Enum {
    static get entries() {
        return {
            financed: 'financed',
            cash: 'cash',
        };
    }

    static get FINANCED() {
        return this.entries.financed;
    }

    static get CASH() {
        return this.entries.cash;
    }

    static get translationGroup() {
        return 'refinancedTypes';
    }
}
