import Model from './Model.js';
import CampaignType from './CampaignType.js';

export default class Campaign extends Model {
    static get defaults() {
        return {
            end_date: null,
            name: '',
            start_date: null,
        };
    }

    static selectOptions(campaigns) {
        return campaigns
            .filter(c => c.type === CampaignType.EVENT)
            .map(campaign => {
                return {
                    id: campaign.id,
                    label: campaign.name,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
    }
}
