import Enum from './Enum.js';

export default class LostSource extends Enum {
    static get entries() {
        return {
            client: 'client',
            dealer: 'dealer',
        };
    }

    static get CLIENT() {
        return this.entries.client;
    }

    static get DEALER() {
        return this.entries.dealer;
    }

    static get translationGroup() {
        return 'lostSources';
    }
}
