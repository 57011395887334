import Enum from './Enum.js';

export default class LeadVehicleRvCategory extends Enum {
    static get entries() {
        return {
            class_a_diesel: 'class_a_diesel',
            hybride: 'hybride',
            motorized_a: 'motorized_a',
            motorized_b: 'motorized_b',
            motorized_c: 'motorized_c',
            travel_trailer: 'travel_trailer',
            fifth_wheel: 'fifth_wheel',
            trailer_park: 'trailer_park',
            tent_trailer: 'tent_trailer',
        };
    }

    static get CLASS_A_DIESEL() {
        return this.entries.class_a_diesel;
    }

    static get HYBRIDE() {
        return this.entries.hybride;
    }

    static get MOTORIZED_A() {
        return this.entries.motorized_a;
    }

    static get MOTORIZED_B() {
        return this.entries.motorized_b;
    }

    static get MOTORIZED_C() {
        return this.entries.motorized_c;
    }

    static get TRAVEL_TRAILER() {
        return this.entries.travel_trailer;
    }

    static get FIFTH_WHEEL() {
        return this.entries.fifth_wheel;
    }

    static get TRAILER_PARK() {
        return this.entries.trailer_park;
    }

    static get TENT_TRAILER() {
        return this.entries.tent_trailer;
    }

    static get translationGroup() {
        return 'leadVehicleRvCategories';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
