<template>
    <div>
        <div :key="criterion.key" v-for="(criterion, index) in structure.criteria">
            <template v-if="isSimpleCriterion(criterion)">
                <criterion
                    :criterion="criterion"
                    :fields-config="fieldsConfig"
                    @remove="removeCriterion(index)"
                    @update:criterion="updateCriteria(index, $event)"
                />
            </template>
            <template v-else-if="isLevelCriterion">
                <div class="sub-level px-4 py-4">
                    <criteria-level
                        :structure="criterion"
                        :fields-config="fieldsConfig"
                        @remove="removeCriterion(index)"
                        @update:criteria="updateCriteria(index, { criteria: $event })"
                        @update:type="updateCriteria(index, { type: $event })"
                    />
                </div>
            </template>

            <div class="flex items-center text-lg" v-if="shouldShowType(index)">
                <span
                    class="cursor-pointer py-2 ml-8"
                    :class="structure.type == 'and' ? 'text-blue-500 font-bold' : 'opacity-25'"
                    @click="updateType('and')"
                >
                    {{ $t('automations.and') }}
                </span>

                <span
                    class="cursor-pointer py-2 ml-2"
                    :class="structure.type == 'or' ? 'text-blue-500 font-bold' : 'opacity-25'"
                    @click="updateType('or')"
                >
                    {{ $t('automations.or') }}
                </span>
            </div>
        </div>

        <div class="flex justify-between items-center mt-4">
            <span class="cursor-pointer" @click="addCriterion">
                {{ $t('automations.addCriterion') }}
            </span>

            <span class="cursor-pointer" @click="addGroup">
                {{ $t('automations.addCriterionGroup') }}
            </span>
        </div>
    </div>
</template>

<script>
    import { cloneDeep } from 'lodash-es';

    import Criterion from './Criterion.vue';

    import AutomationCriterion from '../../entities/AutomationCriterion.js';
    import AutomationCriteriaLevel from '../../entities/AutomationCriteriaLevel.js';

    export default {
        name: 'CriteriaLevel',

        components: {
            Criterion,
        },

        props: {
            structure: {
                type: Object,
                required: true,
            },
            fieldsConfig: {
                type: Object,
                required: true,
            },
        },

        methods: {
            addGroup() {
                const structure = new AutomationCriteriaLevel(cloneDeep(this.structure));

                structure.pushSubLevel();

                this.$emit('update:criteria', structure.criteria);
            },

            addCriterion() {
                const structure = new AutomationCriteriaLevel(cloneDeep(this.structure));

                structure.pushCriterion();

                this.$emit('update:criteria', structure.criteria);
            },

            removeCriterion(index) {
                const structure = new AutomationCriteriaLevel(cloneDeep(this.structure));

                structure.criteria.splice(index, 1);

                this.$emit('update:criteria', structure.criteria);

                if (!structure.criteria.length) {
                    this.$emit('remove');
                }
            },

            shouldShowType(index) {
                const nextIndex = index + 1;

                return !!this.structure.criteria[nextIndex];
            },

            updateCriteria(index, values) {
                const structure = new AutomationCriteriaLevel(cloneDeep(this.structure));

                structure.criteria[index] = { ...this.structure.criteria[index], ...values };

                this.$emit('update:criteria', structure.criteria);
            },

            updateType(value) {
                this.$emit('update:type', value);
            },

            isSimpleCriterion(criterion) {
                return !criterion.criteria && criterion instanceof AutomationCriterion;
            },

            isLevelCriterion(criterion) {
                return criterion.criteria && criterion instanceof AutomationCriterion;
            },
        },
    };
</script>
