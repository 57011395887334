<template>
    <div
        class="group flex items-center"
        :class="disableForEditing ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="openExecutionModal"
    >
        <activix-tooltip :content="iconTooltip">
            <icon
                class="text-2xl mr-1"
                :class="fieldInvalidated ? 'text-orange-500' : 'text-grey-500'"
                name="regular/time-clock-circle"
            />
        </activix-tooltip>

        <!-- Unset -->
        <div
            class="label | text-sm font-normal bg-grey-550 ml-1"
            :class="{ 'group-hover:bg-grey-600': !disableForEditing }"
            v-if="execution.recurrence === null"
        >
            {{ $t('automations.execution.label.unset') }}
        </div>

        <!-- Recurrence -->
        <div
            class="label | text-sm font-normal bg-blue-500 ml-1"
            :class="{ 'group-hover:bg-blue-600': !disableForEditing }"
            v-if="execution.recurrence !== null"
        >
            {{ recurrenceLabel }}
        </div>

        <!-- Delay -->
        <div
            class="label | text-sm font-normal bg-blue-500 ml-1"
            :class="{ 'group-hover:bg-blue-600': !disableForEditing }"
            v-if="execution.hasDelay()"
        >
            {{ delayLabel }}
        </div>

        <!-- Field -->
        <div
            class="label | text-sm font-normal bg-blue-500 ml-1"
            :class="{ 'group-hover:bg-blue-600': !disableForEditing }"
            v-if="execution.field"
        >
            {{ fieldLabel }}
        </div>

        <!-- Time of Day -->
        <div
            class="label | text-sm font-normal bg-blue-500 ml-1"
            :class="{ 'group-hover:bg-blue-600': !disableForEditing }"
            v-if="execution.timeOfDay"
        >
            {{ timeOfDayLabel }}
        </div>

        <!-- Occurences -->
        <div
            class="label | text-sm font-normal bg-blue-500 ml-1"
            :class="{ 'group-hover:bg-blue-600': !disableForEditing }"
            v-if="showOccurrence"
        >
            {{ occurenceLabel }}
        </div>

        <automation-execution-delay
            :automation="automation"
            :execution="execution"
            :action-type="actionType"
            :opened.sync="executionDelayModalOpened"
            @apply="onExecutionApplied"
            @field-invalidated="fieldInvalidated = true"
            @field-validated="fieldInvalidated = false"
        />
    </div>
</template>

<script>
    import AutomationExecutionDelay from '../modals/AutomationExecutionDelay.vue';
    import AutomationExecution from '../../entities/AutomationExecution.js';
    import AutomationRecurrenceType from '../../entities/AutomationRecurrenceType.js';

    export default {
        components: {
            AutomationExecutionDelay,
        },

        props: {
            automation: {
                type: Object,
                required: true,
            },
            execution: {
                type: Object,
                required: true,
            },
            disableForEditing: {
                type: Boolean,
                default: false,
            },
            actionType: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                executionDelayModalOpened: false,
                fieldInvalidated: false,
            };
        },

        computed: {
            showOccurrence() {
                return this.execution.isRecurring();
            },

            occurenceLabel() {
                if (this.execution.isRecurring()) {
                    return this.$tc('automations.execution.label.recurring', this.execution.occurence, {
                        occurence: this.execution.occurence,
                        direction: this.$t(`automations.execution.direction.${this.execution.recurrenceDirection}`),
                    });
                }

                return this.$t('automations.execution.label.now');
            },

            recurrenceLabel() {
                if (this.execution.recurrence !== AutomationRecurrenceType.NOW) {
                    return this.$t(`automations.execution.recurrence.${this.execution.recurrence}`);
                }

                return this.$t('automations.execution.label.now');
            },

            delayLabel() {
                return this.$t('automations.execution.label.delay', {
                    amount: this.execution.delayAmount,
                    unit: this.$tc(`delayUnits.${this.execution.delayUnit}`, this.execution.delayAmount),
                    direction: this.$t(`delayDirections.${this.execution.delayDirection}`),
                });
            },

            fieldLabel() {
                if (this.execution.hasDelay()) {
                    return this.execution.label;
                }

                const localisation =
                    this.execution.field === AutomationExecution.TRIGGER_FIELD
                        ? 'automations.execution.label.fieldShort'
                        : 'automations.execution.label.field';

                return this.$t(localisation, {
                    field: this.execution.label,
                });
            },

            timeOfDayLabel() {
                return this.$t('automations.execution.label.time', { time: this.execution.timeOfDay });
            },

            iconTooltip() {
                return this.fieldInvalidated ? this.$t('automations.execution.tooltips.iconInvalidField') : '';
            },
        },

        methods: {
            openExecutionModal() {
                if (this.disableForEditing) {
                    return;
                }

                this.executionDelayModalOpened = true;
            },

            onExecutionApplied(execution) {
                this.$emit('change', execution);
            },
        },
    };
</script>
