import Enum from './Enum.js';

export default class AutomationScheduleAware extends Enum {
    static get entries() {
        return {
            send: 'send',
            dontSend: 'dont_send',
            nextOpen: 'next_open',
        };
    }

    static get SEND() {
        return this.entries.send;
    }

    static get DONTSEND() {
        return this.entries.dontSend;
    }

    static get NEXTOPEN() {
        return this.entries.nextOpen;
    }

    static get translationGroup() {
        return 'automationSchedule';
    }
}
