<template>
    <div>
        <label>
            {{ label }}
            <span class="form-star" v-if="required && empty(value)">*</span>
            <activix-tooltip :content="tooltip" v-if="tooltip">
                <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
            </activix-tooltip>
        </label>

        <el-select
            height="400px"
            class="white"
            :filterable="filterable"
            :multiple="multiple"
            :collapse-tags="true"
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled"
            @change="$emit('input', $event)"
        >
            <el-option-group v-if="withNone">
                <el-option :label="$t('general.none')" :value="null" key="none" />
            </el-option-group>

            <el-option-group :label="$t(`automations.actions.createTaskEvent.relativeFields`)" v-if="withRelative">
                <el-option
                    :label="relativeOwner.text"
                    :value="relativeOwner.value"
                    :key="relativeOwner.value"
                    v-for="relativeOwner in relativeOwnerOptions"
                />
            </el-option-group>

            <el-option-group :label="$t(`automations.actions.sendNotification.guestGroups`)" v-if="withGuestGroup">
                <el-option
                    :label="guestGroupOption.text"
                    :value="guestGroupOption.value"
                    :key="guestGroupOption.value"
                    v-for="guestGroupOption in guestGroupOptions"
                />
            </el-option-group>

            <el-option-group :label="$t(`automations.actions.createTaskEvent.ownerOptions`)" v-if="ownerOptions">
                <el-option
                    :label="option.text"
                    :value="option.value"
                    :key="option.value"
                    v-for="option in ownerOptions"
                />
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../../../store/store.js';
    import { useContextStore } from '../../../../store/modules/context/store.js';

    export default {
        name: 'UserSelection',

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            filterable: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            tooltip: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [String, Number, Array],
                default: null,
            },
            withNone: {
                type: Boolean,
                default: false,
            },
            withRelative: {
                type: Boolean,
                default: false,
            },
            withGuestGroup: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                executionDelayModalOpened: false,
                fieldInvalidated: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            ownerOptions() {
                return this.activeUsers.map(user => {
                    return {
                        value: user.id,
                        text: `${user.first_name} ${user.last_name}`.trim(),
                    };
                });
            },

            relativeOwnerOptions() {
                return [
                    {
                        value: 'commercial_id',
                        text: this.$t('automations.leadRelatedUser.commercial'),
                        bold: true,
                    },
                    {
                        value: 'service_agent_id',
                        text: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                        bold: true,
                    },
                    {
                        value: 'service_advisor_id',
                        text: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                        bold: true,
                    },
                    {
                        value: 'bdc_user_id',
                        text: this.$t('automations.leadRelatedUser.bdcUser'),
                        bold: true,
                    },
                    {
                        value: 'user_id',
                        text: this.$t('automations.leadRelatedUser.user'),
                        bold: true,
                    },
                ];
            },

            guestGroupOptions() {
                return this.contextAccount.guest_groups.map(group => {
                    return {
                        value: `G-${group.id}`,
                        text: `${group.name}`,
                    };
                });
            },
        },
    };
</script>
