<template>
    <div class="box">
        <div class="box-header | flex items-center border-b">
            <icon class="mr-2 text-xl text-grey-600" name="regular/cog-browser" />
            <h4 class="box-title font-normal" v-text="$t('automations.config.heading')" />
        </div>
        <div class="box-body">
            <validatable-row :is-valid="isValid">
                <div class="flex w-full pb-8">
                    <automation-name class="w-1/3" :automation="automation" @update:is-valid="nameIsValid = $event" />

                    <automation-trigger
                        class="px-4 w-1/3"
                        :automation="automation"
                        @update:automation="$emit('update:automation', $event)"
                        @update:is-valid="triggerIsValid = $event"
                        v-if="triggerIsValid || nameIsValid"
                    />

                    <automation-trigger-field
                        class="px-4 w-1/3"
                        :automation="automation"
                        @update:automation="$emit('update:automation', $event)"
                        v-if="automation.event == 'update' && (automationTriggerFieldEnabled || automation.trigger_field)"
                    />
                </div>
            </validatable-row>
        </div>
    </div>
</template>

<script>
    import AutomationName from './AutomationName.vue';
    import AutomationTrigger from './AutomationTrigger.vue';
    import ValidatableRow from './ValidatableRow.vue';
    import AutomationTriggerField from './AutomationTriggerField.vue';

    export default {
        name: 'AutomationConfigs',

        components: {
            AutomationName,
            AutomationTrigger,
            AutomationTriggerField,
            ValidatableRow,
        },

        inject: ['setAutomationData'],

        props: {
            automation: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                nameIsValid: false,
                triggerIsValid: false,
            };
        },

        computed: {
            isValid() {
                return !!(this.nameIsValid && this.triggerIsValid);
            },

            automationTriggerFieldEnabled() {
                return this.$feature.isEnabled('automation_trigger_field');
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('update:is-valid', newValue);
                },
            },
        },
    };
</script>
