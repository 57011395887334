import Enum from './Enum.js';

export default class LeadVehicleCategory extends Enum {
    static get entries() {
        return {
            atv: 'atv',
            automotive: 'automotive',
            bike: 'bike',
            boat: 'boat',
            caravan: 'caravan',
            hybride: 'hybride',
            mechanical: 'mechanical',
            motorcycle: 'motorcycle',
            motorized: 'motorized',
            snowmobile: 'snowmobile',
            suv: 'suv',
            trailer: 'trailer',
            truck: 'truck',
            utility: 'utility',
            van: 'van',
            watercraft: 'watercraft',
        };
    }

    static get ATV() {
        return this.entries.atv;
    }

    static get AUTOMOTIVE() {
        return this.entries.automotive;
    }

    static get BIKE() {
        return this.entries.bike;
    }

    static get BOAT() {
        return this.entries.boat;
    }

    static get CARAVAN() {
        return this.entries.caravan;
    }

    static get HYBRIDE() {
        return this.entries.hybride;
    }

    static get MECHANICAL() {
        return this.entries.mechanical;
    }

    static get MOTORCYCLE() {
        return this.entries.motorcycle;
    }

    static get MOTORIZED() {
        return this.entries.motorized;
    }

    static get SNOWMOBILE() {
        return this.entries.snowmobile;
    }

    static get SUV() {
        return this.entries.suv;
    }

    static get TRAILER() {
        return this.entries.trailer;
    }

    static get TRUCK() {
        return this.entries.truck;
    }

    static get UTILITY() {
        return this.entries.utility;
    }

    static get VAN() {
        return this.entries.van;
    }

    static get WATERCRAFT() {
        return this.entries.watercraft;
    }

    static get translationGroup() {
        return 'leadVehicleCategories';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
