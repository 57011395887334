import Model from './Model.js';
import AutomationExecution from './AutomationExecution.js';

export default class AutomationAction extends Model {
    static get defaults() {
        return {
            action: null,
            active: false,
            parameters: {},
            execution: {},
        };
    }

    /**
     * Getters
     */

    get execution() {
        return this._execution;
    }

    /**
     * Setters
     */

    set execution(execution) {
        this._execution = new AutomationExecution(execution);
    }

    get hasExecutionField() {
        return this.execution.field && this.execution.field != AutomationExecution.TRIGGER_FIELD;
    }

    get hasDelay() {
        return this.execution.hasDelay();
    }
}
