<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div class="flex flex-wrap">
            <div class="mt-4 w-1/4 pr-4">
                <label>
                    {{ $t('automations.actions.createTaskEvent.type') }}
                    <span class="form-star" v-if="empty(selectedType)">*</span>
                </label>

                <activix-multiselect
                    identifier="value"
                    label="text"
                    white
                    :allow-empty="false"
                    :clearable="true"
                    :options="typeOptions"
                    :placeholder="$t('automations.actions.createTaskEvent.type')"
                    :searchable="false"
                    :selected="selectedType"
                    :disabled="disableForEditing"
                    @update="updateType"
                />
            </div>

            <div class="mt-4 w-1/4 pr-4" v-if="selectedType">
                <user-selection
                    :disabled="disableForEditing"
                    :filterable="true"
                    :label="$t('automations.actions.createTaskEvent.owner')"
                    :placeholder="$t('automations.actions.createTaskEvent.owner')"
                    :required="true"
                    :value="action.parameters.user_id"
                    :with-relative="true"
                    @input="updateOwner"
                />
            </div>

            <div
                class="mt-4 w-1/4 pr-4"
                v-if="selectedType && action.parameters.user_id && !Number.isInteger(action.parameters.user_id)"
            >
                <user-selection
                    :disabled="disableForEditing"
                    :filterable="true"
                    :label="$t('automations.actions.createTaskEvent.backupOwner')"
                    :placeholder="$t('automations.actions.createTaskEvent.backupOwner')"
                    :required="true"
                    :value="action.parameters.backup_user_id"
                    @input="updateBackupOwner"
                />
            </div>

            <div class="mt-4 w-1/4 pr-4" v-if="!isTask && selectedType && action.parameters.user_id">
                <user-selection
                    :disabled="disableForEditing"
                    :label="$t('automations.actions.createTaskEvent.guests')"
                    :multiple="true"
                    :placeholder="$t('automations.actions.createTaskEvent.guests')"
                    :value="action.parameters.guests"
                    :with-relative="true"
                    @input="updateGuests"
                />
            </div>

            <div class="mt-4 w-1/4 pr-4" v-if="selectedType && action.parameters.user_id">
                <label v-text="$t('automations.actions.createTaskEvent.priority')" />

                <activix-multiselect
                    identifier="value"
                    label="text"
                    white
                    :allow-empty="false"
                    :options="priorityOptions"
                    :placeholder="$t('automations.actions.createTaskEvent.priority')"
                    :searchable="false"
                    :selected="selectedPriority"
                    :disabled="disableForEditing"
                    @update="updatePriority"
                />
            </div>

            <div class="w-1/4 mt-4 pr-4" v-if="selectedType && isEvent(action.parameters.type_id) && selectedPriority">
                <label v-text="$t('automations.actions.createTaskEvent.duration')" />

                <activix-multiselect
                    identifier="value"
                    label="text"
                    white
                    :allow-empty="false"
                    :options="durationOptions"
                    :placeholder="$t('automations.actions.createTaskEvent.duration')"
                    :searchable="false"
                    :selected="selectedDuration"
                    :disabled="disableForEditing"
                    @update="updateDuration"
                />
            </div>

            <div class="w-1/4 mt-4 pr-4" v-if="selectedType && selectedOwner && selectedPriority">
                <label v-text="$t('automations.actions.createTaskEvent.taskTitle')" />
                <activix-input
                    white
                    :placeholder="$t('automations.actions.createTaskEvent.taskTitlePlaceholder')"
                    :disabled="disableForEditing"
                    v-model="action.parameters.title"
                />
            </div>

            <div class="mt-4 w-3/4 pr-4" v-if="selectedType && selectedOwner && selectedPriority">
                <label v-text="$t('automations.actions.createTaskEvent.description')" />

                <div class="flex">
                    <textarea-autosize
                        class="form-control input-white | w-full"
                        rows="1"
                        :class="{ 'cursor-not-allowed': disableForEditing }"
                        :min-height="34"
                        :autosize="true"
                        :placeholder="$t('automations.actions.createTaskEvent.description')"
                        :disabled="disableForEditing"
                        ref="textArea"
                        v-model="action.parameters.description"
                    />
                </div>
            </div>
        </div>

        <div class="flex items-center mt-6">
            <activix-checkbox size="sm" v-model="action.parameters.allow_task_assigned_alert">
                <span class="font-normal" v-text="$t('automations.actions.createTaskEvent.allowTaskAssignedAlert')" />
            </activix-checkbox>

            <activix-tooltip :content="$t('automations.actions.createTaskEvent.allowTaskAssignedAlertTooltip')">
                <icon class="text-grey-500 text-sm ml-2" name="regular/information-circle" />
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { cloneDeep, has } from 'lodash-es';
    import { mapState } from 'pinia';
    import TaskEventType from '../../../../entities/TaskEventType.js';

    import UserSelection from './UserSelection.vue';
    import { useContextStore } from '../../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../../store/store.js';

    export default {
        name: 'CreateTaskEvent',

        components: {
            UserSelection,
        },

        props: {
            action: {
                type: Object,
                required: true,
            },
            disableForEditing: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                editDelay: false,
                durationOptions: [
                    {
                        value: 30,
                        text: '0h30m',
                    },
                    {
                        value: 60,
                        text: '1h00m',
                    },
                    {
                        value: 90,
                        text: '1h30m',
                    },
                    {
                        value: 120,
                        text: '2h00m',
                    },
                ],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'configs']),

            duration: {
                get() {
                    return minutesToTime(this.action.parameters.duration);
                },

                set(newValue) {
                    const time = timeToMinutes(newValue);

                    if (this.action.parameters.duration != time) {
                        this.updateAction('parameters', { duration: time });
                    }
                },
            },

            isDelivery() {
                return this.action.parameters.type_id == TaskEventType.DELIVERY;
            },

            isTask() {
                return [TaskEventType.CALL, TaskEventType.SMS, TaskEventType.EMAIL, TaskEventType.CSI].includes(
                    this.action.parameters.type_id,
                );
            },

            isValid() {
                if (!this.action.parameters.user_id || !this.action.parameters.type_id) {
                    return false;
                }

                if (!Number.isInteger(this.action.parameters.user_id) && !this.action.parameters.backup_user_id) {
                    return false;
                }

                if (this.isEvent(this.action.parameters.type_id) && !this.action.parameters.duration) {
                    return false;
                }

                return this.action.execution.isValid();
            },

            filteredTaskEventTypes() {
                return this.configs.taskEventTypes
                    .filter(type => {
                        if (type.name == 'csi' && !this.contextAccount.csi && !this.contextAccount.csi_used) {
                            return false;
                        }

                        return !this.isEvent(type.id) || type.id == TaskEventType.OTHER;
                    })
                    .sort((a, b) => {
                        return a.order.localeCompare(b.order);
                    });
            },

            typeOptions() {
                return this.filteredTaskEventTypes.map(type => {
                    return {
                        value: type.id,
                        text: this.typeTrans(type.id),
                        icon: type.class,
                        type: type.type,
                    };
                });
            },

            priorityOptions() {
                return ['normal', 'high'].map(value => {
                    return {
                        value,
                        text: this.$t(`modal.${value}`),
                    };
                });
            },

            selectedType() {
                const type = this.typeOptions.find(type => {
                    return type.value == this.action.parameters.type_id;
                });

                return type || null;
            },

            selectedPriority() {
                const priority = this.priorityOptions.find(priority => {
                    return priority.value == this.action.parameters.priority;
                });

                return priority || null;
            },

            selectedDuration() {
                const duration = this.durationOptions.find(duration => {
                    return duration.value == this.action.parameters.duration;
                });

                return duration || null;
            },

            selectedOwner() {
                return this.action.parameters.user_id;
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },

            'action'(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },

            'action.parameters.user_id'() {
                if (Number.isInteger(this.action.parameters.user_id)) {
                    this.updateBackupOwner(null);
                }
            },
        },

        methods: {
            isEvent(type) {
                return [
                    TaskEventType.APPOINTMENT,
                    TaskEventType.PHONE_APPOINTMENT,
                    TaskEventType.DELIVERY,
                    TaskEventType.OTHER,
                ].includes(type);
            },

            addReminder(type) {
                const reminderPreferences = this.action.parameters.reminder_preferences || [];

                reminderPreferences.push({
                    date_range: 10,
                    sms: false,
                    email: false,
                    notification: true,
                    push: false,
                    type,
                });

                this.updateAction('parameters', { reminder_preferences: reminderPreferences });
            },

            deleteReminder(reminder) {
                const reminderPreferences = this.action.parameters.reminder_preferences;

                const index = reminderPreferences.indexOf(reminder);
                reminderPreferences.splice(index, 1);

                this.updateAction('parameters', { reminder_preferences: reminderPreferences });
            },

            updateReminder(reminder) {
                const reminderPreferences = this.action.parameters.reminder_preferences;

                const index = reminderPreferences.indexOf(reminder);
                reminderPreferences[index] = reminder;

                this.updateAction('parameters', { reminder_preferences: reminderPreferences });
            },

            updateType(type) {
                this.updateAction('parameters', { type_id: type.value });
            },

            updateOwner(user) {
                this.updateAction('parameters', { user_id: user });
            },

            updateBackupOwner(backupUser) {
                this.updateAction('parameters', { backup_user_id: backupUser });
            },

            updateGuests(guests) {
                this.updateAction('parameters', { guests });
            },

            updatePriority(priority) {
                this.updateAction('parameters', { priority: priority.value });
            },

            updateDuration(duration) {
                this.updateAction('parameters', { duration: duration.value });
            },

            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },

            typeTrans(type) {
                let value = '';

                switch (type) {
                    case TaskEventType.CALL:
                        value = this.$t('modal.call');
                        break;

                    case TaskEventType.EMAIL:
                        value = this.$t('modal.email');
                        break;

                    case TaskEventType.OTHER:
                        value = this.$t('modal.other');
                        break;

                    case TaskEventType.CSI:
                        value = this.$t('clientCard.csi');
                        break;

                    case TaskEventType.SMS:
                        value = this.$t('clientCard.sms');
                        break;
                }

                return value;
            },
        },
    };
</script>
