<template>
    <validatable-row
        :is-valid="criterion.filled"
        :is-invalid="!criterion.valid"
        :validation-icon-tooltip="validationIconTooltip"
        :deletable="true"
        @delete-row="removeCriterion"
    >
        <div class="flex w-full">
            <div class="w-1/3">
                <el-select
                    filterable
                    height="400px"
                    :value="criterion.property"
                    @change="updateCriterion({ property: $event })"
                >
                    <el-option-group
                        :label="$t(`automations.criteriaFields.${model}.group_label`)"
                        :key="model"
                        v-for="(options, model) in propertyOptions"
                    >
                        <el-option
                            :label="item.label"
                            :value="item.key"
                            :key="item.key"
                            v-for="item in options"
                        />
                    </el-option-group>
                </el-select>
            </div>

            <div class="w-1/3 px-4" v-if="showOperatorSelector">
                <activix-multiselect
                    identifier="key"
                    label="label"
                    :allow-empty="false"
                    :options="operatorOptions"
                    :searchable="false"
                    :selected="selectedOperator"
                    @update="updateCriterion({ operator: $event.key })"
                />
            </div>

            <div class="w-1/3" v-if="showValueSelector">
                <activix-masked-input
                    mask-type="number"
                    :allow-zero-value="true"
                    :integer-limit="9"
                    :value="criterion.firstValue"
                    @input="updateCriterion({ value: [$event] })"
                    v-if="isIntegerField"
                />

                <input
                    type="text"
                    class="form-control"
                    :value="criterion.firstValue"
                    @input="updateCriterion({ value: [$event.target.value.toLowerCase()] })"
                    v-if="isInputField"
                />

                <activix-multiselect
                    :multiple="true"
                    :options="selectedPropertyOptions"
                    :searchable="false"
                    :selected="selectedValue"
                    :with-none="true"
                    :none-selected="noneValueSelected"
                    :none-label="getNoneLabel"
                    @update="updateCriterion({ value: $event.map(v => v.id) })"
                    v-if="isSelectField"
                />

                <date-time-picker
                    :date-only="selectedPropertyType == 'date'"
                    :value="criterion.firstValue"
                    @input="updateCriterion({ value: [$event] })"
                    v-if="isDateField"
                />

                <auto-complete
                    :suggestions="selectedPropertyOptions"
                    :selection="criterion.firstValue"
                    @update:selection="updateCriterion({ value: [$event] })"
                    v-if="isAutocompletField"
                />
            </div>
        </div>
    </validatable-row>
</template>

<script>
    import { get, isEqual } from 'lodash-es';

    import { mapState } from 'pinia';
    import AutomationCriterionOperator from '../../entities/AutomationCriterionOperator.js';
    import AutomationCriterionType from '../../entities/AutomationCriterionType.js';
    import User from '../../entities/User.js';

    import AutoComplete from '../inputs/AutoComplete.vue';
    import DateTimePicker from '../inputs/DateTimePicker.vue';
    import ValidatableRow from './ValidatableRow.vue';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            AutoComplete,
            DateTimePicker,
            ValidatableRow,
        },

        props: {
            criterion: {
                type: [Boolean, Object],
                default: false,
                required: true,
            },
            fieldsConfig: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                initialProperty: '',
                initialValue: [],
                operators: AutomationCriterionOperator.selectConfiguration,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            propertyOptions() {
                return Object.entries(this.fieldsConfig).reduce((result, [model, fields]) => {
                    result[model] = fields.filter(field => !field.invalid);
                    return result;
                }, {});
            },

            operatorOptions() {
                return this.operators
                    .filter(operator => {
                        return operator.types.includes(this.selectedPropertyType);
                    })
                    .map(operator => {
                        return {
                            ...operator,
                            label: AutomationCriterionOperator.getTranslation(operator.key, this.selectedPropertyType),
                        };
                    });
            },

            selectedProperty() {
                for (const properties of Object.values(this.propertyOptions)) {
                    const matchingProperty = properties.find(p => p.key == this.criterion.property);
                    if (matchingProperty) {
                        return matchingProperty;
                    }
                }

                return null;
            },

            selectedPropertyOptions() {
                const options = get(this.selectedProperty, 'options', []);

                if (this.isUserProperty) {
                    let invalidUsers = [];
                    this.criterion.value.forEach(id => {
                        if (!options.some(option => option.id == id)) {
                            invalidUsers.push(id);
                        }
                    });

                    invalidUsers = this.contextAccount.users.filter(user => invalidUsers.includes(user.id));

                    return [...options, ...User.selectOptions(invalidUsers)];
                }

                return options.filter(option => !option.invalid);
            },

            selectedPropertyType() {
                return get(this.selectedProperty, 'type');
            },

            selectedOperator() {
                return this.operators.find(option => {
                    return option.key == this.criterion.operator;
                });
            },

            selectedValue() {
                if (!this.isSelectField) {
                    return [];
                }

                return this.selectedPropertyOptions.filter(option => {
                    return this.criterion.value.some(value => value == option.id);
                });
            },

            showOperatorSelector() {
                return this.selectedProperty && this.selectedProperty.key.indexOf('@') == -1;
            },

            showValueSelector() {
                return this.selectedOperator && !this.selectedOperator.implicit;
            },

            isAutocompletField() {
                return [AutomationCriterionType.AUTOCOMPLETE].includes(this.selectedPropertyType);
            },

            isDateField() {
                return [AutomationCriterionType.DATE, AutomationCriterionType.DATETIME].includes(
                    this.selectedPropertyType,
                );
            },

            isInputField() {
                return [AutomationCriterionType.STRING].includes(this.selectedPropertyType);
            },

            isIntegerField() {
                return [AutomationCriterionType.INTEGER, AutomationCriterionType.CURRENCY].includes(
                    this.selectedPropertyType,
                );
            },

            isSelectField() {
                return [AutomationCriterionType.SELECT].includes(this.selectedPropertyType);
            },

            defaultOperator() {
                if (
                    [AutomationCriterionType.BOOLEAN, AutomationCriterionType.METHOD].includes(
                        this.selectedPropertyType,
                    )
                ) {
                    return '=';
                }

                return null;
            },

            defaultValue() {
                if (
                    [AutomationCriterionType.BOOLEAN, AutomationCriterionType.METHOD].includes(
                        this.selectedPropertyType,
                    ) ||
                    ['!', '!!'].includes(get(this.selectedOperator, 'key'))
                ) {
                    return [true];
                }

                if (
                    [AutomationCriterionOperator.LESS_THAN_NOW, AutomationCriterionOperator.GREATER_THAN_NOW].includes(
                        get(this.selectedOperator, 'key'),
                    )
                ) {
                    return ['now()'];
                }

                return [];
            },

            noneValueSelected() {
                return this.criterion.value.includes('none');
            },

            validationIconTooltip() {
                let invalidOptions = [];

                if (this.initialProperty && !this.selectedProperty) {
                    const [model] = this.initialProperty.split(/[[.@]/);
                    const config = this.fieldsConfig[model].find(config => config.key == this.initialProperty);

                    if (config) {
                        invalidOptions.push(config.label);
                    }
                } else if (this.initialValue) {
                    invalidOptions = get(this.selectedProperty, 'options', [])
                        .filter(option => {
                            return option.invalid && this.initialValue.includes(option.id);
                        })
                        .map(option => option.label)
                        .join(', ');
                }

                if (!invalidOptions.length) {
                    return '';
                }

                return this.$t('automations.invalidCriterionTooltip', { options: invalidOptions });
            },

            isUserProperty() {
                return [
                    'lead.bdc_user_id',
                    'lead.commercial_id',
                    'lead.created_by',
                    'lead.delivered_by_id',
                    'lead.service_advisor_id',
                    'lead.service_agent_id',
                    'lead.status_updated_by',
                    'lead.take_over_director_id',
                    'lead.updated_by',
                    'lead.user_id',
                ].includes(this.criterion.property);
            },

            getNoneLabel() {
                return this.criterion.property == 'lead.status' ? this.$t('leadStatus.active') : this.$t('general.none');
            },
        },

        watch: {
            selectedOperator(newValue, oldValue) {
                if (isEqual(newValue, oldValue)) {
                    return;
                }

                this.updateCriterion({ value: this.defaultValue });
            },

            selectedProperty(newValue, oldValue) {
                if (isEqual(newValue, oldValue) || get(newValue, 'type') == get(oldValue, 'type')) {
                    return;
                }

                this.updateCriterion({ operator: this.defaultOperator, value: this.defaultValue });
            },
        },

        methods: {
            removeCriterion() {
                this.$emit('remove');
            },

            updateCriterion(values) {
                this.$emit('update:criterion', values);
            },
        },

        async mounted() {
            this.initialValue = this.criterion.value;
            this.initialProperty = this.criterion.property;
            if (this.isSelectField && this.showValueSelector) {
                this.updateCriterion({
                    value: this.criterion.value.filter(value => {
                        return this.selectedPropertyOptions.find(option => option.id == value) || value == 'none';
                    }),
                });
            }

            await this.$nextTick();

            if (!this.selectedProperty) {
                this.updateCriterion({
                    property: '',
                    operator: null,
                    value: [],
                });
            }
        },
    };
</script>
