<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="flex">
        <div class="w-full mt-4 lg:w-1/4 pr-4">
            <div class="mt-4">
                <activix-multiselect
                    identifier="id"
                    label="beautified_name"
                    white
                    :allow-empty="false"
                    :multiple="false"
                    :options="suppliers"
                    :searchable="false"
                    :selected="action.parameters.supplier"
                    :disabled="disableForEditing"
                    :placeholder="$t('automations.actions.sendWebhook.provider')"
                    @update="updateSelectedSupplier"
                />
            </div>

            <div class="mt-4">
                <activix-input
                    type="text"
                    white
                    :has-error="!fromUrlIsValid"
                    :disabled="disableForEditing"
                    :placeholder="$t('automations.actions.sendWebhook.url')"
                    v-model="action.parameters.url"
                />
            </div>

            <div class="mt-4">
                <activix-input
                    type="email"
                    white
                    :has-error="!fromEmailIsValid"
                    :disabled="disableForEditing"
                    :placeholder="$t('automations.actions.sendWebhook.fallbackEmail')"
                    v-model="action.parameters.emergency_email"
                />
            </div>
        </div>

        <div class="w-full lg:w-2/3">
            <div class="w-full mt-3">
                <label>{{ $t('automations.actions.sendWebhook.apiKey') }}</label>
                <div>
                    <code class="text-grey-800 bg-white break-all shadow" ref="token">{{ apiKeyTrans || '...' }}</code>
                </div>
                <div class="mt-2 text-right" v-if="!!action.parameters.apiKey">
                    <activix-button @click="copyToClipboard">
                        {{ $t('general.copyToClipboard') }}
                    </activix-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { cloneDeep, get, has } from 'lodash-es';

    export default {
        name: 'Webhook',

        props: {
            action: {
                type: Object,
                required: true,
            },
            disableForEditing: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                suppliers: [],
            };
        },

        computed: {
            apiKeyTrans() {
                return this.$route.name == 'automations.update'
                    ? this.action.parameters.apiKey
                    : this.$t('automations.actions.sendWebhook.apiKeySaveNeeded');
            },

            fromEmailIsValid() {
                return this.emailIsValid(this.action.parameters.emergency_email);
            },

            fromUrlIsValid() {
                return this.urlIsValid(this.action.parameters.url);
            },

            isValid() {
                return this.fromEmailIsValid && this.fromUrlIsValid && !!get(this.action, 'parameters.supplier');
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },

            'action'(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },
        },

        methods: {
            filterUniqueSuppliers(data) {
                const suppliers = [
                    ...data,
                    {
                        beautified_name: this.$t('result.other'),
                        company: '',
                        id: null,
                        name: this.$t('result.other'),
                    },
                ];

                const beautifiedNames = [];

                return suppliers.filter(supplier => {
                    let unique = false;

                    if (!beautifiedNames.includes(supplier.beautified_name)) {
                        unique = true;
                        beautifiedNames.push(supplier.beautified_name);
                    }

                    return unique;
                });
            },

            updateSelectedSupplier(value) {
                this.updateAction('parameters', { supplier: value });
            },

            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },

            async fetchSuppliers() {
                const response = await this.$axios.get('v2/suppliers');

                this.suppliers = this.filterUniqueSuppliers(response.data.data);
            },

            copyToClipboard() {
                let range;

                if (document.selection) {
                    // IE
                    range = document.body.createTextRange();
                    range.moveToElementText(this.$refs.token);
                    range.select();
                } else if (window.getSelection) {
                    range = document.createRange();
                    range.selectNode(this.$refs.token);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);
                }

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        this.$notify.success(this.$t('general.copiedSuccessfully'));
                    }
                } catch (err) {
                    this.$notify.warning(this.$t('general.copiedUnsuccessfully'));
                }
            },
        },

        mounted() {
            this.fetchSuppliers();
        },
    };
</script>
