<template>
    <activix-modal
        :opened="opened"
        :closable="false"
        size="sm"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('automations.execution.modal.title') }}
            </h4>
        </template>

        <template slot="body">
            <div class="p-4 -mb-4">
                <!-- Recurrence -->
                <div class="flex items-center mb-4 mr-2 relative" v-if="!isConsent">
                    <div class="w-12">
                        <activix-tooltip :content="$t('automations.execution.modal.label.recurrence')">
                            <icon class="text-xl" name="regular/calendar-sync" />
                        </activix-tooltip>
                    </div>
                    <activix-multiselect
                        class="flex-1"
                        :allow-empty="false"
                        :options="recurrenceOptions"
                        :selected="selectedRecurrence"
                        :searchable="false"
                        @update="onUpdateBuffer('recurrence', $event)"
                    />
                    <span
                        class="text-red-500 font-bold absolute right-0 -mr-6"
                        v-if="!bufferExecution.recurrence"
                    >*</span>
                </div>

                <!-- Occurence -->
                <div class="flex items-center mb-4 mr-2 relative" v-if="bufferExecution.hasRecurrence()">
                    <div class="w-12">
                        <activix-tooltip :content="$t('automations.execution.modal.label.occurence')">
                            <icon class="text-xl" name="regular/button-refresh-one" />
                        </activix-tooltip>
                    </div>
                    <input
                        type="number"
                        class="form-control | flex-1"
                        min="1"
                        max="10"
                        :placeholder="$t('automations.execution.modal.label.occurence')"
                        :value="displayedOccurence"
                        @input="onUpdateBuffer('occurence', { id: $event.target.value })"
                    />
                </div>

                <!-- Recurrence direction -->
                <div class="flex items-center mb-4 mr-2 relative" v-if="bufferExecution.hasRecurrence()">
                    <execution-direction-preview
                        class="w-full ml-12"
                        :occurence="bufferExecution.occurence"
                        :recurrence="bufferExecution.recurrence"
                        :direction="bufferExecution.recurrenceDirection"
                        @change="onUpdateBuffer('recurrenceDirection', { id: $event })"
                    />
                </div>

                <template v-if="showDelay">
                    <hr v-if="!isConsent" />

                    <!-- Delay -->
                    <div class="flex items-center mb-4 mr-2 relative">
                        <div class="w-12">
                            <activix-tooltip :content="$t('automations.execution.modal.label.delay')">
                                <icon class="text-xl" name="regular/stopwatch" />
                            </activix-tooltip>
                        </div>
                        <div class="flex flex-1">
                            <activix-multiselect
                                class="flex-1"
                                :allow-empty="false"
                                :options="delayUnitOptions"
                                :selected="selectedDelayUnit"
                                :searchable="false"
                                :with-none="true"
                                :none-label="$t('automations.execution.modal.label.noDelay')"
                                :none-selected="delayUnitNoneSelected"
                                :placeholder="$t('automations.execution.modal.label.delay')"
                                @update="onUpdateBuffer('delayUnit', $event)"
                            />
                            <input
                                type="number"
                                class="form-control w-24 ml-4"
                                min="0"
                                max="999"
                                :placeholder="$t('automations.execution.modal.label.delay')"
                                :value="displayedDelayAmount"
                                :disabled="!showDelayComplementaryField"
                                @input="onUpdateBuffer('delayAmount', { id: $event.target.value })"
                            />
                        </div>

                        <span class="text-red-500 font-bold absolute right-0 -mr-6" v-if="!delayIsValid">*</span>
                    </div>

                    <!-- Delay direction -->
                    <div class="flex items-center mb-4 mr-2 relative" v-if="showDelayComplementaryField">
                        <div class="w-12">
                            <activix-tooltip :content="$t('automations.execution.modal.label.delayDirection')">
                                <icon class="text-xl" name="regular/expand-horizontal-2" />
                            </activix-tooltip>
                        </div>
                        <activix-multiselect
                            class="flex-1"
                            :allow-empty="false"
                            :options="delayDirectionOptions"
                            :selected="selectedDelayDirection"
                            :searchable="false"
                            @update="onUpdateBuffer('delayDirection', $event)"
                        />
                    </div>
                </template>

                <template v-if="showField">
                    <hr />

                    <!-- Field -->
                    <div class="flex items-center mb-4 mr-2 relative">
                        <div class="w-12">
                            <activix-tooltip :content="$t('automations.execution.modal.label.field')">
                                <icon class="text-xl" name="regular/switch-off-1" />
                            </activix-tooltip>
                        </div>
                        <activix-tooltip :content="isConsent ? $t('automations.execution.tooltips.caslActionRequiresLimitDateField') : ''">
                            <el-select
                                class="flex-1"
                                filterable
                                height="400px"
                                :disabled="isConsent"
                                :value="bufferExecution.field"
                                :clearable="false"
                                :placeholder="$t('automations.execution.modal.label.field')"
                                @change="onUpdateBuffer('field', { id: $event })"
                            >
                                <el-option-group
                                    :label="
                                        model != 'ungrouped' ? $t(`automations.criteriaFields.${model}.group_label`) : ''
                                    "
                                    :key="model"
                                    v-for="(options, model) in fields"
                                >
                                    <el-option
                                        :label="item.label"
                                        :value="item.id"
                                        :key="item.id"
                                        v-for="item in options"
                                    />
                                </el-option-group>
                            </el-select>
                        </activix-tooltip>

                        <span
                            class="text-red-500 font-bold absolute right-0 -mr-6"
                            v-if="!bufferExecution.field"
                        >*</span>
                    </div>
                </template>

                <template v-if="showTypeOfTime">
                    <hr />

                    <div class="flex items-center mb-4 mr-2 relative">
                        <div class="w-12">
                            <activix-tooltip :content="$t('automations.execution.modal.label.typeOfTime')">
                                <icon class="text-xl" name="regular/time-clock-circle" />
                            </activix-tooltip>
                        </div>

                        <el-select
                            class="flex-1"
                            height="400px"
                            :value="bufferExecution.typeOfTime"
                            :clearable="true"
                            :placeholder="$t('automations.execution.modal.label.typeOfTime')"
                            @change="onUpdateBuffer('typeOfTime', { id: $event })"
                        >
                            <el-option
                                :label="item.label"
                                :value="item.id"
                                :key="item.id"
                                v-for="item in typeOfTimeOptions"
                            />
                        </el-select>

                        <span
                            class="text-red-500 font-bold absolute right-0 -mr-6"
                            v-if="!bufferExecution.typeOfTime"
                        >*</span>
                    </div>
                </template>

                <!-- Time of day -->
                <div class="flex items-center mb-4 mr-2 relative" v-if="showTimeOfDay">
                    <div class="col-xs-7 col-xs-offset-3">
                        <el-time-select
                            class="w-full"
                            :placeholder="$t('automations.execution.modal.label.timeOfDay')"
                            :picker-options="timePickerOptions"
                            :value="bufferExecution.timeOfDay"
                            @input="onUpdateBuffer('timeOfDay', { id: $event })"
                        />
                    </div>

                    <span
                        class="text-red-500 font-bold absolute right-0 -mr-6"
                        v-if="!bufferExecution.timeOfDay"
                    >*</span>
                </div>

                <template v-if="bufferExecution.delayUnit && !isNotification">
                    <hr />

                    <!--   Schedule-->
                    <div class="flex items-center mb-4 mr-2 relative">
                        <div class="w-12">
                            <activix-tooltip :content="$t('automations.execution.modal.label.scheduleAware')">
                                <icon class="text-xl" name="regular/shop-sign-open" />
                            </activix-tooltip>
                        </div>
                        <activix-multiselect
                            class="flex-1"
                            :allow-empty="false"
                            :options="scheduleOptions"
                            :selected="selectedSchedule"
                            :searchable="false"
                            @update="onUpdateBuffer('scheduleAware', $event)"
                        />
                    </div>
                </template>
            </div>

        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>

            <activix-button type="primary" :disabled="!bufferExecution.isValid()" @click="onApply">
                {{ $t('automations.execution.modal.applyButton') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    // Utils
    import { merge, cloneDeep, get } from 'lodash-es';
    import { mapState } from 'pinia';

    // Components
    import ExecutionDirectionPreview from '../automations/ExecutionDirectionPreview.vue';

    // Entities
    import ClientCardSection from '../../entities/ClientCardSection.js';
    import AutomationRecurrenceType from '../../entities/AutomationRecurrenceType.js';
    import AutomationDelayUnit from '../../entities/AutomationDelayUnit.js';
    import AutomationDelayDirection from '../../entities/AutomationDelayDirection.js';
    import AutomationExecution from '../../entities/AutomationExecution.js';
    import AutomationCriterionOperator from '../../entities/AutomationCriterionOperator.js';
    import AutomationCriterionType from '../../entities/AutomationCriterionType.js';
    import AutomationScheduleAware from '../../entities/AutomationScheduleAware.js';
    import AutomationActionType from '../../entities/AutomationActionType.js';

    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ExecutionDirectionPreview,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            automation: {
                type: Object,
                required: true,
            },
            execution: {
                type: Object,
                required: true,
            },
            actionType: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                bufferExecution: {},
                timePickerOptions: {
                    start: '00:00',
                    step: '00:15',
                    end: '23:45',
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isConsent() {
                return this.actionType === AutomationActionType.SEND_CONSENT;
            },

            isNotification() {
                return this.actionType === AutomationActionType.SEND_NOTIFICATION;
            },

            fields() {
                const automatableCriteriaFields = merge(
                    cloneDeep(this.configs.automatableCriteriaFields),
                    this.getCustomFields(),
                );

                const fields = this.automation.criteria
                    .filter(criterion => {
                        if (['customer.casl_consent.limit_date', 'lead.created_at'].includes(criterion.property)) {
                            return false;
                        }

                        let fieldTraversablePath = criterion.splitModelAndField.join('.');

                        if (criterion.property.includes('customFields')) {
                            fieldTraversablePath = criterion.property.replace('id|', '').replace('.pivot:value', '').replace(':customFields', 'CustomFields');
                        }

                        const fieldType = get(automatableCriteriaFields, fieldTraversablePath);

                        return (
                            [AutomationCriterionType.DATE, AutomationCriterionType.DATETIME].includes(fieldType) &&
                            AutomationCriterionOperator.positiveOperators.includes(criterion.operator)
                        );
                    })
                    .reduce((result, criterion) => {
                        let [model, field] = criterion.splitModelAndField;
                        let id = `${model}.${field}`;

                        model = model.replace(':customFields', 'CustomFields');

                        if (criterion.property.includes('customFields')) {
                            id = criterion.property;
                        }

                        result[model] = result[model] || [];

                        result[model].push({
                            label: criterion.label,
                            id,
                        });

                        return result;
                    }, []);

                fields.lead = [
                    ...(fields.lead || []),
                    {
                        id: 'lead.created_at',
                        label: this.$t('automations.execution.fields.lead.created_at'),
                    },
                ];

                fields.customer = [
                    ...(fields.customer || []),
                    {
                        id: 'customer.casl_consent.limit_date',
                        label: this.$t('automations.execution.fields.customer.casl_consent.limit_date'),
                    },
                ];

                return {
                    ungrouped: [
                        {
                            id: AutomationExecution.TRIGGER_FIELD,
                            label: this.$t(`automations.execution.fields.${AutomationExecution.TRIGGER_FIELD}`),
                        },
                    ],
                    ...fields,
                };
            },

            typeOfTimeOptions() {
                return [AutomationExecution.FIELD_TIME, AutomationExecution.CUSTOM_TIME].map(option => {
                    return {
                        id: option,
                        label: this.$t(`automations.execution.typeOfTime.${option}`),
                    };
                });
            },

            displayedOccurence() {
                return this.bufferExecution.occurence > 0 ? this.bufferExecution.occurence : '';
            },

            displayedDelayAmount() {
                return this.bufferExecution.delayAmount > 0 ? this.bufferExecution.delayAmount : '';
            },

            showDelayComplementaryField() {
                return this.bufferExecution.delayUnit && !this.delayUnitNoneSelected;
            },

            showDelay() {
                return this.bufferExecution.recurrence == AutomationRecurrenceType.NOW;
            },

            showField() {
                return (
                    this.bufferExecution.recurrence &&
                    (this.bufferExecution.recurrence !== AutomationRecurrenceType.NOW || this.delayIsValid) ||
                    (this.delayIsValid && this.isConsent)
                );
            },

            showTypeOfTime() {
                return (
                    this.showField &&
                    this.bufferExecution.field &&
                    (!this.bufferExecution.delayUnit ||
                        (this.bufferExecution.delayAmount > 0 &&
                            this.bufferExecution.delayUnit !== AutomationDelayUnit.MINUTES &&
                            this.bufferExecution.delayUnit !== AutomationDelayUnit.HOURS))
                );
            },

            showTimeOfDay() {
                return this.bufferExecution.typeOfTime === AutomationExecution.CUSTOM_TIME;
            },

            delayUnitNoneSelected() {
                return this.bufferExecution.delayUnit === 'none';
            },

            delayIsValid() {
                return (
                    this.bufferExecution.delayUnit && (this.delayUnitNoneSelected || this.bufferExecution.delayAmount)
                );
            },

            recurrenceOptions() {
                return AutomationRecurrenceType.selectOptions();
            },

            selectedRecurrence() {
                return AutomationRecurrenceType.getSelectOption(this.bufferExecution.recurrence);
            },

            scheduleOptions() {
                return AutomationScheduleAware.selectOptions(null, null, false);
            },

            selectedSchedule() {
                return AutomationScheduleAware.getSelectOption(this.bufferExecution.scheduleAware || 'send');
            },

            delayUnitOptions() {
                return AutomationDelayUnit.selectOptions();
            },

            selectedDelayUnit() {
                return AutomationDelayUnit.getSelectOption(this.bufferExecution.delayUnit);
            },

            delayDirectionOptions() {
                return AutomationDelayDirection.selectOptions();
            },

            selectedDelayDirection() {
                return AutomationDelayDirection.getSelectOption(this.bufferExecution.delayDirection);
            },
        },

        watch: {
            'bufferExecution.recurrence'(newValue, oldValue) {
                if (typeof oldValue === 'undefined') {
                    return;
                }

                if (newValue === AutomationRecurrenceType.NOW) {
                    this.bufferExecution.occurence = 1;
                    this.bufferExecution.recurrenceDirection = null;
                    this.bufferExecution.typeOfTime = null;
                    this.bufferExecution.timeOfDay = null;
                } else {
                    this.bufferExecution.delayAmount = 0;
                    this.bufferExecution.delayUnit = null;
                    this.bufferExecution.delayDirection = null;
                }
            },

            'bufferExecution.occurence'(newValue) {
                if (isNaN(newValue) || newValue <= 1) {
                    this.bufferExecution.occurence = 1;
                }

                if (newValue > 10) {
                    this.bufferExecution.occurence = 10;
                }
            },

            'bufferExecution.field'(newValue, oldValue) {
                if (typeof oldValue === 'undefined') {
                    return;
                }

                if (newValue == null || newValue === '') {
                    this.bufferExecution.delayDirection = AutomationDelayDirection.AFTER;
                    this.bufferExecution.typeOfTime = null;
                    this.bufferExecution.timeOfDay = null;
                }
            },

            'bufferExecution.delayAmount'(newValue, oldValue) {
                if (typeof oldValue === 'undefined') {
                    return;
                }

                if (isNaN(newValue) || newValue <= 0) {
                    this.bufferExecution.delayAmount = 0;

                    if (!this.isConsent) {
                        this.bufferExecution.field = null;
                    }
                }

                if (newValue > 999) {
                    this.bufferExecution.delayAmount = 999;
                }
            },

            'bufferExecution.delayUnit'(newValue, oldValue) {
                if (typeof oldValue === 'undefined') {
                    return;
                }

                if (
                    newValue === null ||
                    newValue === 'none' ||
                    newValue === AutomationDelayUnit.MINUTES ||
                    newValue === AutomationDelayUnit.HOURS
                ) {
                    this.bufferExecution.delayAmount = null;
                    this.bufferExecution.delayDirection = AutomationDelayDirection.AFTER;

                    if (!this.isConsent) {
                        this.bufferExecution.field = null;
                    }
                }
            },

            fields(newValue, oldValue) {
                const oldHasValue = Object.values(oldValue).some(fields => {
                    return fields.some(field => field.id == this.bufferExecution.field);
                });

                if (oldHasValue) {
                    const newHasValue = Object.values(newValue).some(fields => {
                        return fields.some(field => field.id == this.bufferExecution.field);
                    });

                    if (!newHasValue) {
                        this.$emit('field-invalidated');

                        this.bufferExecution.field = AutomationExecution.TRIGGER_FIELD;
                        this.$emit('apply', this.bufferExecution);
                    }
                }
            },
        },

        methods: {
            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.initBuffer();
            },

            initBuffer() {
                this.bufferExecution = new AutomationExecution({
                    ...this.execution,
                    recurrence: this.isConsent ? AutomationRecurrenceType.NOW : this.execution.recurrence,
                });

                if (this.isConsent) {
                    this.bufferExecution.field = 'customer.casl_consent.limit_date';
                }
            },

            onUpdateBuffer(property, value) {
                this.bufferExecution[property] = value ? value.id : null;
            },

            onApply() {
                this.$emit('apply', this.bufferExecution);
                this.$emit('field-validated');
                this.close();
            },

            getCustomFields() {
                const customFieldsObject = {
                    customFields: {},
                    wantedVehiclesCustomFields: {},
                    exchangeVehiclesCustomFields: {},
                };

                const criterionType = (type) => {
                    switch (type) {
                        case 'array':
                            return AutomationCriterionType.SELECT;

                        case 'datetime':
                            return AutomationCriterionType.DATETIME;

                        case 'boolean':
                            return AutomationCriterionType.BOOLEAN;

                        case 'currency':
                            return AutomationCriterionType.CURRENCY;

                        default:
                            return AutomationCriterionType.STRING;
                    }
                };

                this.contextAccount.getLeadCustomFields().forEach(customField => {
                    customFieldsObject.customFields[customField.id] = criterionType(customField.type);
                });

                this.contextAccount.getLeadVehicleCustomFields().forEach(customField => {
                    if (customField.section == ClientCardSection.VEHICLE_WANTED) {
                        customFieldsObject.wantedVehiclesCustomFields[customField.id] = criterionType(customField.type);
                    }

                    if (customField.section == ClientCardSection.VEHICLE_EXCHANGE) {
                        customFieldsObject.exchangeVehiclesCustomFields[customField.id] = criterionType(customField.type);
                    }
                });

                return customFieldsObject;
            },
        },

        created() {
            this.initBuffer();
        },
    };
</script>
