import Enum from './Enum.js';

export default class LeadVehicleTradeType extends Enum {
    static get entries() {
        return {
            excluded: 'excluded',
            lost: 'lost',
            recycled: 'recycled',
            retail: 'retail',
            return: 'return',
            wholesale: 'wholesale',
        };
    }

    static get EXCLUDED() {
        return this.entries.excluded;
    }

    static get LOST() {
        return this.entries.lost;
    }

    static get RECYCLED() {
        return this.entries.recycled;
    }

    static get RETAIL() {
        return this.entries.retail;
    }

    static get RETURN() {
        return this.entries.return;
    }

    static get WHOLESALE() {
        return this.entries.wholesale;
    }

    static get translationGroup() {
        return 'leadVehicleTradeTypes';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
