<template>
    <label class="uppercase font-semibold tracking-wide" v-text="label" />
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: '',
            },
        },
    };
</script>
