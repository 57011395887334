import Enum from './Enum.js';
import i18n from '../plugins/vue-i18n.js';
import AutomationCriterionType from './AutomationCriterionType.js';

export default class AutomationCriterionOperator extends Enum {
    static get entries() {
        return {
            equal: '=',
            notEqual: '!=',
            contains: '~',
            notContains: '!~',
            lessThan: '<',
            greaterThan: '>',
            greaterThanNow: '->',
            lessThanNow: '-<',
            defined: '!!',
            undefined: '!',
        };
    }

    static get EQUAL() {
        return this.entries.equal;
    }

    static get NOT_EQUAL() {
        return this.entries.notEqual;
    }

    static get CONTAINS() {
        return this.entries.contains;
    }

    static get NOT_CONTAINS() {
        return this.entries.notContains;
    }

    static get LESS_THAN() {
        return this.entries.lessThan;
    }

    static get LESS_THAN_NOW() {
        return this.entries.lessThanNow;
    }

    static get GREATER_THAN() {
        return this.entries.greaterThan;
    }

    static get GREATER_THAN_NOW() {
        return this.entries.greaterThanNow;
    }

    static get DEFINED() {
        return this.entries.defined;
    }

    static get UNDEFINED() {
        return this.entries.undefined;
    }

    static get positiveOperators() {
        return [this.EQUAL, this.CONTAINS, this.LESS_THAN, this.GREATER_THAN, this.DEFINED];
    }

    static getTranslation(searchId, forType) {
        switch (searchId) {
            case this.EQUAL:
                return this.equalOperatorName(forType);
            case this.NOT_EQUAL:
                return this.notEqualOperatorName(forType);
            case this.CONTAINS:
                return this.containsOperatorName();
            case this.NOT_CONTAINS:
                return this.notContainsOperatorName();
            case this.LESS_THAN:
                return this.lessThanOperatorName(forType);
            case this.LESS_THAN_NOW:
                return this.lessThanNowOperatorName(forType);
            case this.GREATER_THAN:
                return this.greaterThanOperatorName(forType);
            case this.GREATER_THAN_NOW:
                return this.greaterThanNowOperatorName(forType);
            case this.DEFINED:
                return this.definedOperatorName(forType);
            case this.UNDEFINED:
                return this.undefinedOperatorName(forType);
        }

        return '';
    }

    static equalOperatorName(forType) {
        switch (forType) {
            case AutomationCriterionType.BOOLEAN:
                return i18n.t('automations.operators.isChecked');
            default:
                return i18n.t('automations.operators.is');
        }
    }

    static notEqualOperatorName(forType) {
        switch (forType) {
            case AutomationCriterionType.BOOLEAN:
                return i18n.t('automations.operators.isNotChecked');
            default:
                return i18n.t('automations.operators.isNot');
        }
    }

    static containsOperatorName() {
        return i18n.t('automations.operators.contains');
    }

    static notContainsOperatorName() {
        return i18n.t('automations.operators.notContains');
    }

    static lessThanOperatorName(forType) {
        switch (forType) {
            case AutomationCriterionType.DATE:
            case AutomationCriterionType.DATETIME:
                return i18n.t('automations.operators.before');
            default:
                return i18n.t('automations.operators.lessThan');
        }
    }

    static lessThanNowOperatorName() {
        return i18n.t('automations.operators.lessThanNow');
    }

    static greaterThanOperatorName(forType) {
        switch (forType) {
            case AutomationCriterionType.DATE:
            case AutomationCriterionType.DATETIME:
                return i18n.t('automations.operators.after');
            default:
                return i18n.t('automations.operators.greaterThan');
        }
    }

    static greaterThanNowOperatorName() {
        return i18n.t('automations.operators.greaterThanNow');
    }

    static definedOperatorName() {
        return i18n.t('automations.operators.defined');
    }

    static undefinedOperatorName() {
        return i18n.t('automations.operators.undefined');
    }

    static get selectConfiguration() {
        return [
            {
                key: this.DEFINED,
                types: [
                    AutomationCriterionType.AUTOCOMPLETE,
                    AutomationCriterionType.CURRENCY,
                    AutomationCriterionType.DATE,
                    AutomationCriterionType.DATETIME,
                    AutomationCriterionType.DEFINED,
                    AutomationCriterionType.INTEGER,
                    AutomationCriterionType.SELECT,
                    AutomationCriterionType.STRING,
                ],
                implicit: true,
            },
            {
                key: this.UNDEFINED,
                types: [
                    AutomationCriterionType.AUTOCOMPLETE,
                    AutomationCriterionType.CURRENCY,
                    AutomationCriterionType.DATE,
                    AutomationCriterionType.DATETIME,
                    AutomationCriterionType.DEFINED,
                    AutomationCriterionType.INTEGER,
                    AutomationCriterionType.SELECT,
                    AutomationCriterionType.STRING,
                ],
                implicit: true,
            },
            {
                key: this.EQUAL,
                types: [
                    AutomationCriterionType.AUTOCOMPLETE,
                    AutomationCriterionType.BOOLEAN,
                    AutomationCriterionType.CURRENCY,
                    AutomationCriterionType.DATE,
                    AutomationCriterionType.DATETIME,
                    AutomationCriterionType.INTEGER,
                    AutomationCriterionType.SELECT,
                    AutomationCriterionType.STRING,
                ],
            },
            {
                key: this.NOT_EQUAL,
                types: [
                    AutomationCriterionType.AUTOCOMPLETE,
                    AutomationCriterionType.BOOLEAN,
                    AutomationCriterionType.CURRENCY,
                    AutomationCriterionType.DATE,
                    AutomationCriterionType.DATETIME,
                    AutomationCriterionType.INTEGER,
                    AutomationCriterionType.SELECT,
                    AutomationCriterionType.STRING,
                ],
            },
            {
                key: this.CONTAINS,
                types: [AutomationCriterionType.STRING],
            },
            {
                key: this.NOT_CONTAINS,
                types: [AutomationCriterionType.STRING],
            },
            {
                key: this.LESS_THAN,
                types: [
                    AutomationCriterionType.CURRENCY,
                    AutomationCriterionType.DATE,
                    AutomationCriterionType.DATETIME,
                    AutomationCriterionType.INTEGER,
                ],
            },
            {
                key: this.GREATER_THAN,
                types: [
                    AutomationCriterionType.CURRENCY,
                    AutomationCriterionType.DATE,
                    AutomationCriterionType.DATETIME,
                    AutomationCriterionType.INTEGER,
                ],
            },
            {
                key: this.LESS_THAN_NOW,
                types: [AutomationCriterionType.DATE, AutomationCriterionType.DATETIME],
                implicit: true,
            },
            {
                key: this.GREATER_THAN_NOW,
                types: [AutomationCriterionType.DATE, AutomationCriterionType.DATETIME],
                implicit: true,
            },
        ];
    }
}
