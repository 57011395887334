<template>
    <div class="box">
        <div class="box-header | flex items-center border-b">
            <icon class="mr-2 text-xl text-grey-600" name="regular/checklist" />
            <h4 class="box-title font-normal" v-text="$t('automations.criteria')" />
        </div>

        <div class="box-body">
            <criteria-level
                :structure="automation.criteria_structure"
                :fields-config="fieldsConfig"
                @update:criteria="updateCriteria('criteria', $event)"
                @update:type="updateCriteria('type', $event)"
                v-if="!$wait.is('fetching.automation')"
            />
        </div>
    </div>
</template>

<script>
    import { cloneDeep, merge } from 'lodash-es';

    import { mapState } from 'pinia';
    import CriteriaLevel from './CriteriaLevel.vue';

    import ClientCardSection from '../../entities/ClientCardSection.js';
    import AutomationCriterionFieldConfig from '../../entities/AutomationCriterionFieldConfig.js';
    import AutomationCriteriaLevel from '../../entities/AutomationCriteriaLevel.js';
    import AutomationCriterionType from '../../entities/AutomationCriterionType.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            CriteriaLevel,
        },

        inject: ['setAutomationData'],

        props: {
            automation: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['configs', 'authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isValid() {
                return this.criterionIsValid(this.automation.criteria_structure);
            },

            fieldsConfig() {
                const automatableCriteriaFields = merge(
                    cloneDeep(this.configs.automatableCriteriaFields),
                    this.getCustomFields(),
                );

                return Object.entries(automatableCriteriaFields).reduce((result, [model, fields]) => {
                    const criteria = AutomationCriterionFieldConfig[model](fields, this.contextAccount, this.automation.criteria_structure.criteria, this.authUser);

                    if (criteria.length) {
                        result[model] = criteria;
                    }

                    return result;
                }, {});
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('update:is-valid', newValue);
                },
            },

            'automation.criteria_structure'() {
                this.setAutomationData({
                    criteria: this.extractCriterion(this.automation.criteria_structure.criteria),
                });
            },
        },

        methods: {
            extractCriterion(criteria) {
                const extractedCriteria = [];
                criteria.forEach(criterion => {
                    if (criterion.criteria) {
                        extractedCriteria.push(...this.extractCriterion(criterion.criteria));
                    } else {
                        extractedCriteria.push(criterion);
                    }
                });

                return extractedCriteria;
            },

            criterionIsValid(criterion) {
                if (criterion.criteria) {
                    return criterion.criteria.every(criterion => this.criterionIsValid(criterion));
                }

                return criterion.filled;
            },

            updateCriteria(key, value) {
                this.setAutomationData({
                    criteria_structure: new AutomationCriteriaLevel({
                        ...this.automation.criteria_structure,
                        [key]: value,
                    }),
                });
            },

            getCustomFields() {
                const customFieldsObject = {
                    customFields: {},
                    wantedVehiclesCustomFields: {},
                    exchangeVehiclesCustomFields: {},
                };

                const criterionType = (type) => {
                    switch (type) {
                        case 'array':
                            return AutomationCriterionType.SELECT;

                        case 'datetime':
                            return AutomationCriterionType.DATETIME;

                        case 'boolean':
                            return AutomationCriterionType.BOOLEAN;

                        case 'currency':
                            return AutomationCriterionType.CURRENCY;

                        default:
                            return AutomationCriterionType.STRING;
                    }
                };

                this.contextAccount.getLeadCustomFields().forEach(customField => {
                    customFieldsObject.customFields[`[id|${customField.id}].pivot:value`] = criterionType(customField.type);
                });

                this.contextAccount.getLeadVehicleCustomFields().forEach(customField => {
                    if (customField.section == ClientCardSection.VEHICLE_WANTED) {
                        customFieldsObject.wantedVehiclesCustomFields[`[id|${customField.id}].pivot:value`] = criterionType(customField.type);
                    }

                    if (customField.section == ClientCardSection.VEHICLE_EXCHANGE) {
                        customFieldsObject.exchangeVehiclesCustomFields[`[id|${customField.id}].pivot:value`] = criterionType(customField.type);
                    }
                });

                return customFieldsObject;
            },
        },
    };
</script>
