import AutomationCriterionType from './AutomationCriterionType.js';
import Campaign from './Campaign.js';
import Civility from './Civility.js';
import CommunicationMethod from './CommunicationMethod.js';
import CreatedMethod from './CreatedMethod.js';
import Division from './Division.js';
import LeadResult from './LeadResult.js';
import LeadStatus from './LeadStatus.js';
import LeadType from './LeadType.js';
import LeadVehicle from './LeadVehicle.js';
import LeadVehicleCategory from './LeadVehicleCategory.js';
import LeadVehicleCondition from './LeadVehicleCondition.js';
import LeadVehicleFrequency from './LeadVehicleFrequency.js';
import LeadVehicleFuel from './LeadVehicleFuel.js';
import LeadVehicleIntention from './LeadVehicleIntention.js';
import LeadVehicleModality from './LeadVehicleModality.js';
import LeadVehicleRvCategory from './LeadVehicleRvCategory.js';
import LeadVehicleStockState from './LeadVehicleStockState.js';
import LeadVehicleTireType from './LeadVehicleTireType.js';
import LeadVehicleTradeType from './LeadVehicleTradeType.js';
import LeadVehicleTransmission from './LeadVehicleTransmission.js';
import Locale from './Locale.js';
import User from './User.js';
import i18n from '../plugins/vue-i18n.js';
import LostSource from './LostSource.js';
import ProgressState from './ProgressState.js';
import RefinancedType from './RefinancedType.js';
import Sex from './Sex.js';
import AccountType from './AccountType.js';
import { useGlobalStore } from '../store/store.js';

export default class AutomationCriterionFieldConfig {
    static LEAD = 'lead';

    static ACCOUNT = 'account';

    static CUSTOMER = 'customer';

    static WANTED_VEHICLES = 'wantedVehicles';

    static EXCHANGE_VEHICLES = 'exchangeVehicles';

    static CUSTOMFIELDS = 'customFields';

    static WANTED_VEHICLES_CUSTOMFIELDS = 'wantedVehicles:customFields';

    static EXCHANGE_VEHICLES_CUSTOMFIELDS = 'exchangeVehicles:customFields';

    static CUSTOMFIELDS_MODELS = [this.CUSTOMFIELDS, this.WANTED_VEHICLES_CUSTOMFIELDS, this.EXCHANGE_VEHICLES_CUSTOMFIELDS];

    static SOURCE = 'source';

    static generateFieldsConfig(model, field, type, invalid = false, options = [], label = '', tooltip = '') {
        const fieldSeparator = type != AutomationCriterionType.METHOD && !this.CUSTOMFIELDS_MODELS.includes(model) ? '.' : '';
        const tooltipPath = `automations.criteriaFields.${model}.${field}Tooltip`;
        tooltip = tooltip || (i18n.te(tooltipPath) ? i18n.t(tooltipPath) : '');

        return {
            key: `${model}${fieldSeparator}${field}`,
            label: label || this.getFieldLabel(`${model}.${field}`),
            invalid,
            options,
            tooltip,
            type,
        };
    }

    static lead(fields, account, criterion, authUser) {
        return Object.entries(fields)
            .map(([field, type]) => {
                const label = '';
                let options = [];
                const invalid = !account.hasClientCardField(field);
                const valueUsed = criterion.filter(c => c.property == `lead.${field}`).map(c => c.value).flat();

                switch (field) {
                    case 'bdc_user_id':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'first_event_campaign_id':
                        options = Campaign.selectOptions(account.campaigns);
                        break;
                    case 'civility':
                        options = Civility.selectOptions();
                        break;
                    case 'commercial_id':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'communication_preference':
                        options = CommunicationMethod.selectOptions();
                        break;
                    case 'created_by':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'created_method':
                        options = CreatedMethod.selectOptions(account);
                        break;
                    case 'delivered_by_id':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'division_id':
                        options = Division.selectOptions(account);
                        break;
                    case 'form_normalized':
                        options = account.allForms;
                        break;
                    case 'lead_bank_id':
                        options = useGlobalStore().configs.leadBanks
                            .map(bank => {
                                return {
                                    id: bank.id,
                                    label: bank.name,
                                };
                            })
                            .sort((a, b) => a.label.localeCompare(b.label));
                        break;
                    case 'lead_type_id':
                        options = LeadType.selectOptions(account, authUser, valueUsed);
                        break;
                    case 'locale':
                        options = Locale.selectOptions();
                        break;
                    case 'lost_source':
                        options = LostSource.selectOptions();
                        break;
                    case 'progress_state':
                        options = ProgressState.selectOptions();
                        break;
                    case 'province':
                        options = useGlobalStore().configs.provinces[account.country].map(province => {
                            return {
                                id: province,
                                label: i18n.t(`clientCard.provinces.${province}`),
                            };
                        }).sort((a, b) => a.label.localeCompare(b.label));
                        break;
                    case 'source_id':
                        options = account.sources
                            .map(source => {
                                return {
                                    id: source.id,
                                    label: source.name,
                                };
                            })
                            .sort((a, b) => a.label.localeCompare(b.label));
                        break;
                    case 'refinanced_type':
                        options = RefinancedType.selectOptions();
                        break;
                    case 'result_normalized':
                        options = LeadResult.selectOptions();
                        break;
                    case 'service_advisor_id':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'service_agent_id':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'sex':
                        options = Sex.selectOptions();
                        break;
                    case 'status':
                        options = LeadStatus.selectOptions();
                        break;
                    case 'status_updated_by':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'take_over_director_id':
                        options = User.selectOptions(account.directorUsers);
                        break;
                    case 'updated_by':
                        options = User.selectOptions(account.activeUsers);
                        break;
                    case 'user_id':
                        options = User.selectOptions(account.activeUsers);
                        break;
                }

                return this.generateFieldsConfig(this.LEAD, field, type, invalid, options, label);
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static generateCustomFields(model, fields, account) {
        return Object.entries(fields)
            .map(([field, type]) => {
                const fieldId = field.match(/^\[.*\|([0-9]*)]/)[1];
                const customField = account.getCustomFieldById(fieldId);
                const invalid = !customField.visible;
                const label = customField.name;
                const options = customField.select_picker_options
                    .map(option => {
                        return {
                            id: option,
                            label: option,
                        };
                    })
                    .sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });

                return this.generateFieldsConfig(model, field, type, invalid, options, label);
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static customFields(fields, account) {
        return this.generateCustomFields(this.CUSTOMFIELDS, fields, account);
    }

    static wantedVehiclesCustomFields(fields, account) {
        return this.generateCustomFields(this.WANTED_VEHICLES_CUSTOMFIELDS, fields, account);
    }

    static exchangeVehiclesCustomFields(fields, account) {
        return this.generateCustomFields(this.EXCHANGE_VEHICLES_CUSTOMFIELDS, fields, account);
    }

    static account(fields) {
        return Object.entries(fields)
            .map(([field, type]) => this.generateFieldsConfig(this.ACCOUNT, field, type))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static customer(fields) {
        return Object.entries(fields)
            .map(([field, type]) => this.generateFieldsConfig(this.CUSTOMER, field, type))
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static wantedVehicles(fields, account) {
        return Object.entries(fields)
            .map(([field, type]) => {
                const label =
                    this.getFieldLabel(`vehicle.${field}`) + this.getFieldLabel(`${this.WANTED_VEHICLES}.group_abr`);

                let options = [];
                const invalid = account.type == AccountType.CUSTOM || !LeadVehicle.hasWantedField(field, account);

                switch (field) {
                    case 'category':
                        options = LeadVehicleCategory.selectOptions();
                        break;
                    case 'category_rv':
                        options = LeadVehicleRvCategory.selectOptions();
                        break;
                    case 'frequency':
                        options = LeadVehicleFrequency.selectOptions();
                        break;
                    case 'fuel':
                        options = LeadVehicleFuel.selectOptions();
                        break;
                    // TODO Transfer make and model into a combined criteria
                    // case 'make':
                    //     if (!account.vehicle_text) {
                    //         options = fetchVehicleMakes().then(res => {
                    //             return res
                    //                 .map(make => {
                    //                     return {
                    //                         id: make.name,
                    //                         label: make.name,
                    //                     };
                    //                 })
                    //                 .sort((a, b) => a.label.localeCompare(b.label));
                    //         });
                    //     }
                    //     break;
                    case 'modality':
                        options = LeadVehicleModality.selectOptions();
                        break;
                    case 'stock_state':
                        options = LeadVehicleStockState.selectOptions();
                        break;
                    case 'transmission':
                        options = LeadVehicleTransmission.selectOptions();
                        break;
                    case 'verified_by_id':
                        options = User.selectOptions(account.directorUsers);
                        break;
                }

                return this.generateFieldsConfig(this.WANTED_VEHICLES, field, type, invalid, options, label);
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static exchangeVehicles(fields, account) {
        return Object.entries(fields)
            .map(([field, type]) => {
                const label =
                    this.getFieldLabel(`vehicle.${field}`) + this.getFieldLabel(`${this.EXCHANGE_VEHICLES}.group_abr`);

                let options = [];
                const invalid = account.type == AccountType.CUSTOM || !LeadVehicle.hasExchangeField(field, account);

                switch (field) {
                    case 'bank_id':
                        options = useGlobalStore().configs.leadBanks
                            .map(bank => {
                                return {
                                    id: bank.id,
                                    label: bank.name,
                                };
                            })
                            .sort((a, b) => a.label.localeCompare(b.label));
                        break;
                    case 'category':
                        options = LeadVehicleCategory.selectOptions();
                        break;
                    case 'category_rv':
                        options = LeadVehicleRvCategory.selectOptions();
                        break;
                    case 'condition':
                        options = LeadVehicleCondition.selectOptions();
                        break;
                    case 'frequency':
                        options = LeadVehicleFrequency.selectOptions();
                        break;
                    case 'fuel':
                        options = LeadVehicleFuel.selectOptions();
                        break;
                    case 'intention':
                        options = LeadVehicleIntention.selectOptions();
                        break;
                    // TODO Transfer make and model into a combined criteria
                    // case 'make':
                    //     if (!account.vehicle_text) {
                    //         options = fetchVehicleMakes().then(result => {
                    //             return result
                    //                 .map(make => {
                    //                     return {
                    //                         id: make.name,
                    //                         label: make.name,
                    //                     };
                    //                 })
                    //                 .sort((a, b) => a.label.localeCompare(b.label));
                    //         });
                    //     }
                    //     break;
                    case 'modality':
                        options = LeadVehicleModality.selectOptions();
                        break;
                    case 'stock_state':
                        options = LeadVehicleStockState.selectOptions();
                        break;
                    case 'tire_type':
                        options = LeadVehicleTireType.selectOptions();
                        break;
                    case 'trade_type':
                        options = LeadVehicleTradeType.selectOptions();
                        break;
                    case 'transmission':
                        options = LeadVehicleTransmission.selectOptions();
                        break;
                }

                return this.generateFieldsConfig(this.EXCHANGE_VEHICLES, field, type, invalid, options, label);
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static source(fields, account) {
        return Object.entries(fields)
            .map(([field, type]) => {
                const label = '';
                let options = [];
                const invalid = !account.hasClientCardField(field);

                switch (field) {
                    case 'provider_id':
                        options = account.providers
                            .map(provider => {
                                return {
                                    id: provider.id,
                                    label: provider.name,
                                };
                            })
                            .sort((a, b) => a.label.localeCompare(b.label));
                        break;
                }

                return this.generateFieldsConfig(this.SOURCE, field, type, invalid, options, label);
            })
            .sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
    }

    static getFieldLabel(key) {
        return i18n.t(`automations.criteriaFields.${key}`);
    }
}
