import Enum from './Enum.js';

export default class AutomationRecurrenceDirection extends Enum {
    static get entries() {
        return {
            past: 'past',
            future: 'future',
        };
    }

    static get PAST() {
        return this.entries.past;
    }

    static get FUTURE() {
        return this.entries.future;
    }
}
