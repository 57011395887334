<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="flex">
        <div class="w-full lg:w-1/4 pr-4">
            <div class="mt-4">
                <activix-multiselect
                    white
                    :allow-empty="false"
                    :multiple="false"
                    :options="divisions"
                    :searchable="false"
                    :selected="selectedDivision"
                    :placeholder="$t('automations.actions.sendEmail.division')"
                    @update="updateSelectedDivision"
                />
            </div>

            <div class="mt-4">
                <div class="flex items-center">
                    <activix-multiselect
                        label="title"
                        white
                        :allow-empty="false"
                        :disabled="!selectedDivision || $wait.is('fetching.templates')"
                        :options="responseTemplatesFiltered"
                        :selected="selectedTemplate"
                        class="flex-1"
                        :placeholder="$t('automations.actions.sendEmail.template')"
                        @update="updateSelectedTemplate"
                    />
                    <div class="flex items-center">
                        <icon
                            :name="$icons.loading"
                            class="link-grey text-sm ml-3"
                            :class="{ 'spin-inverse': $wait.is('fetching.templates') }"
                            @click="refreshTemplates"
                        />
                        <router-link
                            class="link-grey ml-2 text-sm flex items-center"
                            target="_blank"
                            :to="{ name: 'templates.store', params: {type: 'email'} }"
                            v-if="authUser.hasAccessTo('templates.store')"
                        >
                            <icon name="bold/add" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <label>
                    {{ $t('automations.actions.sendEmail.sender') }}
                    <span class="form-star" v-if="!selectedRole || !action.parameters.fallback_user_id">*</span>
                </label>
                <activix-multiselect
                    identifier="key"
                    label="label"
                    white
                    :allow-empty="false"
                    :multiple="false"
                    :options="roles"
                    :searchable="false"
                    :selected="selectedRole"
                    @update="updateSelectedRole"
                />
            </div>

            <div class="mt-4" v-if="selectedRole">
                <div class="mb-4" v-if="action.parameters.from_email && !action.parameters.fallback_user_id">
                    <label class="flex items-center" v-if="!isCustomRole">
                        {{ $t('automations.actions.sendEmail.fallback') }}
                    </label>
                    <activix-tooltip :content="$t('automations.actions.sendEmail.disabledFallBackEmailTooltip')">
                        <activix-input
                            type="email"
                            white
                            disabled
                            v-model="action.parameters.from_email"
                        />
                    </activix-tooltip>
                </div>

                <user-selection
                    :multiple="false"
                    :filterable="false"
                    :label="!isCustomRole ? $t('automations.actions.sendEmail.fallbackSender') : ''"
                    :required="false"
                    :value="action.parameters.fallback_user_id"
                    :with-relative="false"
                    :with-none="true"
                    @input="updateFallbackFromUserEmail"
                />
            </div>

            <div class="mt-4" v-if="selectedRole && action.parameters.affect_lead_status">
                <user-selection
                    :multiple="false"
                    :filterable="false"
                    :label="$t('automations.actions.sendEmail.assignTo')"
                    :tooltip="$t('automations.actions.sendEmail.assignToTooltip')"
                    :required="false"
                    :value="action.parameters.assign_to"
                    :with-relative="false"
                    :with-none="true"
                    @input="updateAssignTo"
                />
            </div>

            <hr />

            <div class="flex items-center mt-4">
                <activix-checkbox size="sm" v-model="useLeadLocale">
                    <span class="font-normal" v-text="$t('automations.actions.sendEmail.useLeadLocale')" />
                </activix-checkbox>
                <activix-tooltip :content="$t('automations.actions.sendEmail.useLeadLocaleTooltip')">
                    <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                </activix-tooltip>
            </div>

            <div class="flex items-center mt-4">
                <activix-checkbox size="sm" v-model="action.parameters.affect_lead_status">
                    <span class="font-normal" v-text="$t('automations.actions.sendEmail.affectLeadResult')" />
                </activix-checkbox>
                <activix-tooltip :content="$t('automations.actions.sendEmail.affectLeadResultTooltip')">
                    <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                </activix-tooltip>
            </div>

            <template v-if="!useLeadLocale">
                <div class="mt-4">
                    <label class="flex items-center">
                        {{ $t('automations.actions.sendEmail.localePreference') }}
                        <activix-tooltip :content="$t('automations.actions.sendEmail.localePreferenceTooltip')">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                    </label>
                    <activix-multiselect
                        identifier="key"
                        label="label"
                        white
                        :allow-empty="false"
                        :multiple="false"
                        :options="localeOptions"
                        :searchable="false"
                        :selected="selectedLocaleOption"
                        @update="updateSelectedLocale"
                    />
                </div>

                <div class="mt-4" v-if="localeOption == 'bilingual'">
                    <label v-text="$t('automations.actions.sendEmail.changeLocaleOrder')" />

                    <div class="flex items-center">
                        <div>
                            <div
                                class="border border-grey-300 px-3 my-2"
                                :key="locale"
                                v-text="locale.toUpperCase()"
                                v-for="locale in localeOrder"
                            />
                        </div>

                        <div class="ml-3">
                            <icon
                                name="regular/data-transfer-vertical"
                                class="link-blue text-2xl"
                                @click="localeOrder = [...localeOrder].reverse()"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="flex flex-col w-full lg:w-3/4 mt-4 mr-4">
            <div class="panel panel-default | flex-grow mb-0">
                <div class="panel-heading flex justify-between">
                    <span v-html="previewSubject" />
                    <a @click="curentLocale = curentLocale == 'en' ? 'fr' : 'en'">
                        {{ curentLocale == 'en' ? 'FR' : 'EN' }}
                    </a>
                </div>

                <div class="panel-body">
                    <span v-html="previewBody" />
                </div>
            </div>

            <div class="mt-4" v-if="selectedTemplate && selectedTemplate.attachments.length">
                <label>{{ $t('automations.actions.sendEmail.attachments') }}</label>
                <div>
                    <div class="list-group-item" :key="index" v-for="(file, index) in selectedTemplate.attachments">
                        {{ file.name != undefined ? file.name : '' }} - {{ attachmentSize(file) }} MB
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    // Utils
    import { camelCase, cloneDeep, get, isArray, isEmpty, isEqual, orderBy, has } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import TemplateType from '@/entities/TemplateType';
    import { round } from '../../../utils/numbers.js';

    // Mixins
    import EmailCompose from '../../../mixins/EmailCompose.js';

    // Entities
    import Division from '../../../entities/Division.js';

    import UserSelection from './CreateTaskEvent/UserSelection.vue';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        name: 'SendEmail',
        components: { UserSelection },
        mixins: [EmailCompose],

        props: {
            action: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                template: null,
                curentLocale: this.$i18n.locale,
                previewBody: this.$t('templates.previewMessage'),
                previewSubject: `<b>${this.$t('templates.subject.email')}: </b>`,
                templateBody: '',
                templateSubject: '',
                localeOrder: ['fr', 'en'],
                localeOption: 'bilingual',
                useLeadLocale: true,
                replyEmail: '',
                roles: [
                    {
                        key: 'user',
                        label: this.$t('automations.leadRelatedUser.user'),
                    },
                    {
                        key: 'bdcUser',
                        label: this.$t('automations.leadRelatedUser.bdcUser'),
                    },
                    {
                        key: 'serviceAdvisorUser',
                        label: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                    },
                    {
                        key: 'serviceAgentUser',
                        label: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                    },
                    {
                        key: 'commercial',
                        label: this.$t('automations.leadRelatedUser.commercial'),
                    },
                    {
                        key: 'custom',
                        label: this.$t('automations.leadRelatedUser.custom'),
                    },
                ],
                localeOptions: [
                    {
                        key: 'fr',
                        label: 'FR',
                    },
                    {
                        key: 'en',
                        label: 'EN',
                    },
                    {
                        key: 'bilingual',
                        label: this.$t('accounts.edit.bilingual'),
                    },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                authUser: 'authUser',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                filteredContextReponseTemplates: 'filteredContextReponseTemplates',
            }),

            selectedLocaleOption() {
                return this.localeOptions.find(locale => locale.key == this.localeOption);
            },

            isCustomRole() {
                return this.selectedRole && this.selectedRole.key == 'custom';
            },

            selectedRole() {
                return this.roles.find(role => role.key == this.action.parameters.from_role);
            },

            selectedTemplate() {
                return this.responseTemplatesFiltered.find(
                    template => template.id == this.action.parameters.template.id,
                );
            },

            selectedDivision() {
                return this.divisions.find(division => division.id == this.action.parameters.template.division_id);
            },

            divisions() {
                const divisionIds = [];

                this.filteredContextReponseTemplates.forEach(template => {
                    if (!divisionIds.includes(template.division_id)) {
                        divisionIds.push(template.division_id);
                    }
                });

                const divisions = divisionIds.map(division => {
                    const divisionKey = Object.keys(Division.entries).find(key => Division.entries[key] == division);

                    return {
                        id: division,
                        label: this.$t(`divisions.${camelCase(divisionKey)}`),
                    };
                });

                return orderBy(divisions, ['label']);
            },

            responseTemplatesFiltered() {
                if (!this.selectedDivision) {
                    return [];
                }

                const templates = this.filteredContextReponseTemplates
                    .filter(template => {
                        return (
                            template.type === TemplateType.EMAIL &&
                            template.division_id == this.selectedDivision.id &&
                            template.translations.some(translation => translation.locale == this.curentLocale)
                        );
                    })
                    .map(template => {
                        const translation = template.translations.find(trans => trans.locale == this.curentLocale);

                        const fallbackLang = this.curentLocale == 'fr' ? 'en' : 'fr';
                        const fallbackTranslation = template.translations.find(trans => trans.locale == fallbackLang);

                        const title = template.title || translation?.title || fallbackTranslation?.title;

                        return {
                            id: template.id,
                            main: !template.parent,
                            subject: translation.subject,
                            body: translation.body,
                            title: template.parent
                                ? `[${this.$t('leadXpress.parent')}] ${title}`
                                : title,
                            attachments: translation.attachments,
                            division_id: template.division_id,
                        };
                    });

                return orderBy(templates, ['title']);
            },

            isValid() {
                return !!this.selectedDivision &&
                    !!this.selectedTemplate &&
                    !!this.selectedRole &&
                    !!this.action.parameters.fallback_user_id &&
                    this.action.execution.isValid();
            },

            templateAttachments() {
                return get(this.selectedTemplate, 'attachments', null);
            },
        },

        watch: {
            localeOrder(newValue, oldValue) {
                if (!isEqual(newValue, oldValue)) {
                    this.updateLocaleParameters();
                }
            },

            localeOption(newValue, oldValue) {
                if (!isEqual(newValue, oldValue)) {
                    this.updateLocaleParameters();
                }
            },

            useLeadLocale(newValue, oldValue) {
                if (!isEqual(newValue, oldValue)) {
                    this.updateLocaleParameters();
                }
            },

            'action.parameters.locales'(newValue, oldValue) {
                if (!isEqual(newValue, oldValue)) {
                    this.setLocaleOptions(newValue);
                }
            },

            'action'(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },

            templateAttributes() {
                this.updatePreview();
            },

            selectedTemplate(newValue) {
                this.templateBody = get(newValue, 'body', '');
                this.templateSubject = get(newValue, 'subject', '');
                this.updatePreview();
            },

            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['reloadContextAccount']),

            attachmentSize(attachment) {
                return round(attachment.size / 1000000, 2);
            },

            async refreshTemplates() {
                this.$wait.start('fetching.templates');

                await this.reloadContextAccount();

                this.$wait.end('fetching.templates');
            },

            updateSelectedDivision(value) {
                const template = { ...this.action.parameters.template, division_id: get(value, 'id', null) };

                this.updateAction('parameters', { template });
            },

            updateSelectedTemplate(value) {
                const template = { ...this.action.parameters.template, id: get(value, 'id', null) };

                this.updateAction('parameters', { template });
            },

            updateSelectedRole(value) {
                this.updateAction('parameters', { from_role: get(value, 'key', null) });
            },

            updateFallbackFromUserEmail(value) {
                this.updateAction('parameters', { fallback_user_id: value });
            },

            updateAssignTo(value) {
                this.updateAction('parameters', { assign_to: value });
            },

            updateSelectedLocale(value) {
                this.localeOption = get(value, 'key', null);
            },

            updatePreview() {
                if (!isEmpty(this.selectedTemplate)) {
                    const payload = {
                        sender: this.authUser,
                        locale: this.curentLocale,
                        massMailing: true,
                        users: this.contextAccount.users,
                    };

                    this.previewSubject = this.replaceAttributesInMessage({
                        ...payload,
                        html: false,
                        message: this.selectedTemplate.subject,
                    });
                    this.previewBody = this.replaceAttributesInMessage({
                        ...payload,
                        html: true,
                        message: this.selectedTemplate.body,
                    });
                } else {
                    this.previewSubject = this.$t('templates.previewMessage');
                    this.previewBody = `<b>${this.$t('templates.subject.email')}: </b>`;
                }
            },

            setLocaleOptions(locales) {
                if (!locales) {
                    return;
                }

                let leadLocale = false;

                locales.forEach(locale => {
                    if (locale == 'lead') {
                        leadLocale = true;
                    } else if (isArray(locale)) {
                        this.localeOption = 'bilingual';
                        this.localeOrder = locale;
                    } else {
                        this.localeOption = locale;
                    }
                });

                this.useLeadLocale = leadLocale;
            },

            updateLocaleParameters() {
                const locales = [];

                if (this.useLeadLocale) {
                    locales.push('lead');
                }

                if (this.localeOption == 'bilingual') {
                    locales.push(this.localeOrder);
                } else {
                    locales.push(this.localeOption);
                }

                this.updateAction('parameters', { locales });
            },

            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },
        },

        mounted() {
            this.setLocaleOptions(this.action.parameters.locales || []);
            this.updatePreview();
        },
    };
</script>
