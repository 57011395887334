<template>
    <validatable-row
        :is-valid="currentAction.valid"
        :align-center="false"
        :deletable="!disableForEditing"
        @delete-row="removeAction"
    >
        <div class="flex flex-wrap flex-1 pt-1 bg-grey-100 rounded-lg shadow-md" v-if="currentAction">
            <div class="w-full flex flex-wrap items-center pt-4 p-5">
                <span class="flex w-full md:w-1/4">
                    <activix-multiselect
                        class="pr-4"
                        identifier="key"
                        label="label"
                        white
                        :allow-empty="false"
                        :options="automatableOptions"
                        :searchable="false"
                        :selected="selectedAction"
                        :disabled="disableForEditing"
                        placeholder="Action"
                        @update="updateAction"
                    />
                    <span class="form-star" v-if="!selectedAction">*</span>
                </span>

                <template v-if="showActionExecution">
                    <action-execution
                        :automation="automation"
                        :execution="currentAction.execution"
                        :action-type="currentAction.action"
                        :disable-for-editing="disableForEditing"
                        @change="onExecutionChanged"
                    />

                    <span class="form-star | ml-3" v-if="!currentAction.execution.isValid()">*</span>
                </template>

                <activix-switcher
                    class="xs | mt-1 mr-4 ml-auto"
                    :disabled="disableForEditing"
                    :value="currentAction.active"
                    @input="updateActionStatus($event)"
                />
            </div>

            <div class="pt-4 w-full p-5 bg-grey-100">
                <send-email
                    :action="currentAction"
                    @update:action="$emit('update:action', $event)"
                    v-if="showSendEmailAction"
                />

                <send-sms
                    :action="currentAction"
                    @update:action="$emit('update:action', $event)"
                    v-if="showSendSmsAction"
                />

                <webhook
                    :action="currentAction"
                    :disable-for-editing="disableForEditing"
                    @update:action="$emit('update:action', $event)"
                    v-if="showWebhookAction"
                />

                <create-task-event
                    :action="currentAction"
                    :disable-for-editing="disableForEditing"
                    @update:action="$emit('update:action', $event)"
                    v-if="showCreateTaskEventAction"
                />

                <send-notification
                    :action="currentAction"
                    :disable-for-editing="disableForEditing || !automationNotificationEnabled"
                    @update:action="$emit('update:action', $event)"
                    v-if="showSendNotificationAction"
                />

                <send-system-notification
                    :action="currentAction"
                    @update:action="$emit('update:action', $event)"
                    v-if="showSendSystemNotificationAction"
                />

                <send-consent-double-opt-in
                    :action="currentAction"
                    @update:action="$emit('update:action', $event)"
                    v-if="showSendConsentDoubleOptInAction"
                />
            </div>
        </div>
    </validatable-row>
</template>

<script>
    import { cloneDeep } from 'lodash-es';

    import { mapState } from 'pinia';
    import SendEmail from './actions/SendEmail.vue';
    import SendConsentDoubleOptIn from './actions/SendConsentDoubleOptIn.vue';
    import SendSms from './actions/SendSms.vue';
    import Webhook from './actions/Webhook.vue';
    import SendNotification from './actions/SendNotification.vue';
    import SendSystemNotification from './actions/SendSystemNotification.vue';
    import CreateTaskEvent from './actions/CreateTaskEvent/CreateTaskEvent.vue';
    import ValidatableRow from './ValidatableRow.vue';
    import ActionExecution from './ActionExecution.vue';

    import AutomationActionType from '../../entities/AutomationActionType.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        name: 'Action',

        components: {
            ActionExecution,
            SendEmail,
            SendConsentDoubleOptIn,
            SendSms,
            Webhook,
            SendNotification,
            SendSystemNotification,
            CreateTaskEvent,
            ValidatableRow,
        },

        props: {
            automation: {
                type: Object,
                required: true,
            },
            action: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            automationNotificationEnabled() {
                return this.$feature.isEnabled('automation-notification');
            },

            showActionExecution() {
                return [
                    AutomationActionType.CREATE_TASK_EVENT,
                    AutomationActionType.SEND_EMAIL,
                    AutomationActionType.SEND_SMS,
                    AutomationActionType.SEND_NOTIFICATION,
                    AutomationActionType.SEND_SYSTEM_NOTIFICATION,
                    AutomationActionType.SEND_CONSENT,
                ].includes(this.currentAction.action);
            },

            showSendEmailAction() {
                if (this.currentAction.action !== AutomationActionType.SEND_EMAIL) {
                    return false;
                }

                return !!this.automation.id || this.currentAction.execution.isValid();
            },

            showSendConsentDoubleOptInAction() {
                if (this.currentAction.action !== AutomationActionType.SEND_CONSENT) {
                    return false;
                }

                return !!this.automation.id || this.currentAction.execution.isValid();
            },

            showSendSmsAction() {
                if (this.currentAction.action !== AutomationActionType.SEND_SMS) {
                    return false;
                }

                return !!this.automation.id || this.currentAction.execution.isValid();
            },

            showWebhookAction() {
                return this.currentAction.action === AutomationActionType.SEND_WEBHOOK;
            },

            showSendNotificationAction() {
                if (this.currentAction.action !== AutomationActionType.SEND_NOTIFICATION) {
                    return false;
                }

                return !!this.automation.id || this.currentAction.execution.isValid();
            },

            showSendSystemNotificationAction() {
                return this.currentAction.action === AutomationActionType.SEND_SYSTEM_NOTIFICATION;
            },

            showCreateTaskEventAction() {
                if (this.currentAction.action !== AutomationActionType.CREATE_TASK_EVENT) {
                    return false;
                }

                return !!this.automation.id || this.currentAction.execution.isValid();
            },

            currentAction() {
                return this.action;
            },

            selectedAction() {
                return this.automatableOptions.find(action => {
                    return action.key === this.currentAction.action;
                });
            },

            automatableOptions() {
                return this.configs.automatableActions.filter(action => {
                    if (this.automationNotificationEnabled) {
                        return true;
                    }

                    return action != AutomationActionType.SEND_NOTIFICATION;
                }).map(action => {
                    return {
                        key: action,
                        label: this.getLabel(action),
                        disabled: this.actionIsDisabled(action),
                    };
                }).sort((a, b) => a.label.localeCompare(b.label));
            },

            disableForEditing() {
                return this.actionIsDisabled(this.currentAction.action);
            },
        },

        methods: {
            actionIsDisabled(action) {
                if (action === AutomationActionType.SEND_WEBHOOK) {
                    return !this.authUser.isAdmin();
                }

                if (action === AutomationActionType.SEND_SMS) {
                    return !this.contextAccount.hasNioText() || !this.authUser.automation_advanced_access;
                }

                if (action === AutomationActionType.SEND_NOTIFICATION) {
                    return !this.authUser.automation_advanced_access;
                }

                if (action === AutomationActionType.SEND_SYSTEM_NOTIFICATION) {
                    return !this.automation.system_type_id;
                }

                if (action === AutomationActionType.CREATE_TASK_EVENT) {
                    return !this.authUser.automation_advanced_access;
                }

                return false;
            },

            getLabel(key) {
                return this.$t(`automations.actions.${key}.title`);
            },

            updateAction({ key }) {
                this.$emit('update:action', {
                    action: key,
                    parameters: cloneDeep(this.configs.automationActionsParams[key]),
                });
            },

            removeAction() {
                if (this.disableForEditing) {
                    return;
                }

                this.$emit('remove');
            },

            updateActionStatus(event) {
                this.$emit('update:action', { active: event });
            },

            onExecutionChanged(execution) {
                this.$emit('update:action', { execution });
            },
        },
    };
</script>
