import Enum from './Enum.js';

export default class Locale extends Enum {
    static get entries() {
        return {
            m: 'm',
            w: 'w',
        };
    }

    static get M() {
        return this.entries.m;
    }

    static get W() {
        return this.entries.w;
    }

    static get translationGroup() {
        return 'sex';
    }
}
