<template>
    <div class="flex w-full">
        <div class="w-full">
            <label>
                {{ $t('automations.config.triggerFieldTitle') }}
            </label>

            <activix-tooltip :content="$t('automations.config.triggerFieldTooltipMessage')">
                <icon class="text-sm ml-1 flex-shrink-0" name="regular/information-circle" />
            </activix-tooltip>

            <el-select
                filterable
                height="400px"
                :value="automation.trigger_field"
                :placeholder="$t('automations.fields.all')"
                :disabled="automation.trigger_field && !$feature.isEnabled('automation_trigger_field')"
                @change="updateTriggerField({ trigger_field: $event })"
            >
                <el-option
                    :label="$t('automations.fields.all')"
                    :value="null"
                    :key="null"
                />

                <el-option-group
                    :label="$t(`automations.model.${model}`)"
                    :key="model"
                    v-for="(options, model) in configs.automatableTriggerFields"
                >
                    <el-option
                        :label="item.label"
                        :value="item.key"
                        :key="item.key"
                        v-for="item in getFields(model)"
                    />
                </el-option-group>
            </el-select>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        inject: ['setAutomationData'],

        props: {
            automation: {
                type: [Boolean, Object],
                default: false,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
        },

        methods: {
            removeTriggerField() {
                this.$emit('remove');
            },

            updateTriggerField(values) {
                this.setAutomationData(values);
            },

            getFields(model) {
                return this.configs.automatableTriggerFields[model].map(field => {
                    return {
                        key: `${model}.${field}`,
                        label: this.$t(`automations.criteriaFields.${model}.${field}`),
                    };
                });
            },
        },
    };
</script>
