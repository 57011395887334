/* eslint-disable camelcase */
import Model from './Model.js';
import AutomationAction from './AutomationAction.js';
import AutomationCriterion from './AutomationCriterion.js';
import AutomationState from './AutomationState.js';
import AutomationCriteriaLevel from './AutomationCriteriaLevel.js';

export default class Automation extends Model {
    static get defaults() {
        return {
            account: null,
            actions: [],
            active: false,
            criteria: [],
            criteria_structure: {},
            trigger_field: null,
            event: null,
            model: null,
            name: '',
            fields: [],
            status: '',
            affected_models: 0,
            visible: true,
        };
    }

    /**
     * Getters
     */

    get actions() {
        return this._actions;
    }

    get criteria_structure() {
        return this._criteria_structure;
    }

    get criteria() {
        return this._criteria;
    }

    /**
     * Setters
     */

    set actions(actions) {
        this._actions = actions.map(action => new AutomationAction(action));
    }

    set criteria_structure(structure) {
        this._criteria_structure = new AutomationCriteriaLevel(structure);
    }

    set criteria(criteria) {
        this._criteria = criteria.map(criterion => new AutomationCriterion(criterion));
    }

    get atLeastOneActionActive() {
        return this.actions.some(action => action.active);
    }

    get hasExecutionField() {
        return this.actions.some(action => action.hasExecutionField);
    }

    get automationUpdatedAt() {
        const sortedDate = this.parseUpdatedDate();

        return sortedDate.length ? sortedDate[0].updated_at : '-';
    }

    get automationUpdatedBy() {
        const sortedDate = this.parseUpdatedDate();

        return sortedDate.length ? sortedDate[0].updated_by : null;
    }

    get hasDelay() {
        return this.actions.some(action => action.hasDelay);
    }

    get hasInvalidCriterion() {
        return this.criteria.some(criterion => !criterion.valid);
    }

    get state() {
        if (this.retroaction_execution_start_at && this.retroaction_execution_end_at) {
            return AutomationState.RETROACTION_COMPLETED;
        }

        if (this.retroaction_execution_start_at && !this.retroaction_execution_end_at) {
            return AutomationState.RETROACTION_IN_PROGRESS;
        }

        return AutomationState.NO_RETROACTION;
    }

    get noRetroaction() {
        return this.state == AutomationState.NO_RETROACTION;
    }

    get retroactionCompleted() {
        return this.state == AutomationState.RETROACTION_COMPLETED;
    }

    get retroactionInProgress() {
        return this.state == AutomationState.RETROACTION_IN_PROGRESS;
    }

    parseStructure() {
        this.criteria_structure.parseCriteria(this.criteria);
    }

    parseUpdatedDate() {
        return [...this.actions, ...this.criteria].sort((a, b) => {
            return as_locale(a.updated_at).isAfter(as_locale(b.updated_at)) ? -1 : 1;
        });
    }
}
