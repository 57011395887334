<template>
    <div class="flex" :class="alignCenter ? 'items-center' : ''">
        <slot />
        <div class="ml-4">
            <div class="flex items-center justify-center text-lg" :class="{ 'mt-8': !alignCenter }">
                <icon
                    :name="$icons.trash"
                    class="link-grey-light mr-3 | hover:text-red-500"
                    @click="$emit('delete-row')"
                    v-if="deletable"
                />

                <activix-tooltip :content="validationIconTooltip">
                    <icon :name="iconName" :class="iconStyle" />
                </activix-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isValid: {
                type: Boolean,
                default: false,
            },
            isInvalid: {
                type: Boolean,
                default: false,
            },
            alignCenter: {
                type: Boolean,
                default: true,
            },
            deletable: {
                type: Boolean,
                default: false,
            },
            validationIconTooltip: {
                type: String,
                default: '',
            },
        },

        computed: {
            iconName() {
                if (this.isInvalid) {
                    return 'bold/remove-circle';
                }

                if (this.isValid) {
                    return 'bold/check-circle-1';
                }

                return 'bold/remove-circle';
            },

            iconStyle() {
                if (this.isInvalid) {
                    return 'opacity-100 text-orange-500';
                }

                if (this.isValid) {
                    return 'opacity-100 text-blue-500';
                }

                return 'opacity-25';
            },
        },
    };
</script>
