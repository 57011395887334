<template>
    <div class="flex justify-between items-center h-10 overflow-hidden">
        <div class="truncate italic">
            <activix-tooltip
                placement="top-start"
                :content="$t('automations.execution.tooltips.direction')"
                v-if="occurence"
            >
                <span class="text-grey-600">
                    {{ directionPreview }}
                </span>
            </activix-tooltip>
            <span class="text-grey-500" v-else>
                {{ $t('automations.execution.modal.noPreview') }}
            </span>
        </div>
        <div class="ml-2">
            <activix-tooltip :content="directionButtonTooltip">
                <icon class="text-xl link-grey" :name="directionIcon" @click="onChangeDirection" />
            </activix-tooltip>
        </div>
    </div>
</template>

<script>
    import RecurrenceDirection from '../../entities/AutomationRecurrenceDirection.js';
    import AutomationRecurrenceType from '../../entities/AutomationRecurrenceType.js';

    export default {
        props: {
            recurrence: {
                type: String,
                required: true,
            },
            direction: {
                type: String,
                default: 'future',
            },
            occurence: {
                type: Number,
                default: 1,
            },
        },

        computed: {
            directionPreview() {
                const repeat = this.occurence > 10 ? 10 : this.occurence;
                const direction = this.direction || RecurrenceDirection.FUTURE;
                const startDate = now();
                const offset = direction == RecurrenceDirection.FUTURE ? 1 : -1;

                switch (this.recurrence) {
                    case AutomationRecurrenceType.DAILY:
                        startDate.addDays(offset);
                        break;
                    case AutomationRecurrenceType.WEEKLY:
                        startDate.addWeeks(offset);
                        break;
                    case AutomationRecurrenceType.MONTHLY:
                        startDate.addMonths(offset);
                        break;
                    case AutomationRecurrenceType.YEARLY:
                        startDate.addYears(offset);
                        break;
                }

                const dates = [...Array(repeat).keys()].map(index => {
                    switch (this.recurrence) {
                        case AutomationRecurrenceType.DAILY:
                            return direction === RecurrenceDirection.FUTURE
                                ? startDate
                                    .clone()
                                    .addDays(index)
                                    .format('DD MMM')
                                : startDate
                                    .clone()
                                    .subDays(index)
                                    .format('DD MMM');
                        case AutomationRecurrenceType.WEEKLY:
                            return direction === RecurrenceDirection.FUTURE
                                ? startDate
                                    .clone()
                                    .addWeeks(index)
                                    .format('DD MMM')
                                : startDate
                                    .clone()
                                    .subWeeks(index)
                                    .format('DD MMM');
                        case AutomationRecurrenceType.MONTHLY:
                            return direction === RecurrenceDirection.FUTURE
                                ? startDate
                                    .clone()
                                    .addMonths(index)
                                    .format('DD MMM')
                                : startDate
                                    .clone()
                                    .subMonths(index)
                                    .format('DD MMM');
                        case AutomationRecurrenceType.YEARLY:
                            return direction === RecurrenceDirection.FUTURE
                                ? startDate
                                    .clone()
                                    .addYears(index)
                                    .format('DD MMM YYYY')
                                : startDate
                                    .clone()
                                    .subYears(index)
                                    .format('DD MMM YYYY');
                        default:
                            return now();
                    }
                });
                return dates.join(', ');
            },

            directionIcon() {
                const direction = this.direction || RecurrenceDirection.FUTURE;
                return direction === RecurrenceDirection.FUTURE
                    ? 'regular/navigation-right-circle_1'
                    : 'regular/navigation-left-circle-1';
            },

            directionButtonTooltip() {
                const direction = this.direction || RecurrenceDirection.FUTURE;
                return direction === RecurrenceDirection.FUTURE
                    ? this.$t('automations.execution.tooltips.directionButtonFuture')
                    : this.$t('automations.execution.tooltips.directionButtonPast');
            },
        },

        methods: {
            onChangeDirection() {
                const direction = this.direction || RecurrenceDirection.FUTURE;

                const newDirection =
                    direction === RecurrenceDirection.FUTURE ? RecurrenceDirection.PAST : RecurrenceDirection.FUTURE;
                this.$emit('change', newDirection);
            },
        },
    };
</script>
