<template>
    <div class="flex">
        <div class="mt-4 w-1/4 pr-4">
            <user-selection
                :multiple="true"
                :filterable="false"
                :label="$t('automations.actions.sendSystemNotification.to')"
                :placeholder="$t('automations.actions.sendSystemNotification.to')"
                :required="true"
                :value="action.parameters.to_role"
                :with-relative="true"
                @input="updateSelectedRole"
            />
        </div>
        <div class="mt-4 w-1/4 pr-4" v-if="selectedRole">
            <label v-text="$t('automations.actions.sendSystemNotification.frequency')" />
            <activix-multiselect
                identifier="key"
                label="label"
                white
                :allow-empty="false"
                :multiple="false"
                :options="frequencyOptions"
                :searchable="false"
                :selected="selectedFrequency"
                @update="updateSelectedFrequency"
            />
        </div>
        <div class="mt-4 w-1/4 pr-4" v-if="selectedRole">
            <label v-text="$t('automations.actions.sendSystemNotification.notification')" />
            <activix-multiselect
                identifier="key"
                label="label"
                white
                :allow-empty="false"
                :multiple="false"
                :options="notificationOptions"
                :searchable="false"
                :selected="selectedNotification"
                @update="updateSelectedNotification"
            />
        </div>
    </div>
</template>

<script>
    import { cloneDeep, get, has } from 'lodash-es';

    import { mapState } from 'pinia';
    import UserSelection from './CreateTaskEvent/UserSelection.vue';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        name: 'SendSystemNotification',

        components: {
            UserSelection,
        },

        props: {
            action: {
                type: Object,
                required: true,
            },

            disableForEditing: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                frequencyOptions: [
                    {
                        key: 'daily',
                        label: this.$t('automations.actions.sendSystemNotification.daily'),
                        disabled: true,
                    },
                    {
                        key: 'weekly',
                        label: this.$t('automations.actions.sendSystemNotification.weekly'),
                    },
                ],

                notificationOptions: [
                    {
                        key: 'renewalAlertEquity',
                        label: this.$t('users.edit.renewalAlertEquity'),
                    },
                    {
                        key: 'renewalAlertEndLocation',
                        label: this.$t('users.edit.renewalAlertEndLocation'),
                    },
                    {
                        key: 'renewalAlertEndWarranty',
                        label: this.$t('users.edit.renewalAlertEndWarranty'),
                    },
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['activeUsers']),

            isValid() {
                return !!this.action.parameters.notification && this.action.execution.isValid();
            },

            rolesAndUsers() {
                return [
                    {
                        key: 'user',
                        label: this.$t('automations.leadRelatedUser.user'),
                    },
                    {
                        key: 'bdcUser',
                        label: this.$t('automations.leadRelatedUser.bdcUser'),
                    },
                    {
                        key: 'serviceAdvisorUser',
                        label: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                    },
                    {
                        key: 'serviceAgentUser',
                        label: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                    },
                    {
                        key: 'commercial',
                        label: this.$t('automations.leadRelatedUser.commercial'),
                    },
                    ...this.activeUsers.map(user => {
                        return {
                            key: user.id,
                            label: `${user.first_name} ${user.last_name}`.trim(),
                        };
                    }),
                ];
            },

            selectedRole() {
                return this.rolesAndUsers.filter(
                    roleUser => this.action.parameters.to_role && this.action.parameters.to_role.includes(roleUser.key),
                );
            },

            selectedFrequency() {
                return this.frequencyOptions.find(frequency => frequency.key == this.action.parameters.frequency);
            },

            selectedNotification() {
                return this.notificationOptions.find(
                    notification => notification.key == this.action.parameters.notification,
                );
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },

            'action'(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },
        },

        methods: {
            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },

            updateSelectedRole(value) {
                this.updateAction('parameters', { to_role: value });
            },

            updateSelectedFrequency(value) {
                this.updateAction('parameters', { frequency: get(value, 'key', null) });
            },

            updateSelectedNotification(value) {
                this.updateAction('parameters', { notification: get(value, 'key', null) });
            },
        },
    };
</script>
