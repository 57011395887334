<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="flex">
        <div class="w-full lg:w-1/4 pr-4">

            <div class="mt-4">
                <div class="flex items-center">
                    <activix-multiselect
                        label="title"
                        white
                        :allow-empty="false"
                        :disabled="$wait.is('fetching.templates') || locked"
                        :options="responseTemplatesFiltered"
                        :selected="selectedTemplate"
                        class="flex-1"
                        :placeholder="$t('automations.actions.sendNotification.template')"
                        @update="updateSelectedTemplate"
                    />
                    <div class="flex items-center">
                        <icon
                            :name="$icons.loading"
                            class="link-grey text-sm ml-3"
                            :class="{ 'spin-inverse': $wait.is('fetching.templates') }"
                            @click="refreshTemplates"
                        />
                        <router-link
                            class="link-grey ml-2 text-sm flex items-center"
                            target="_blank"
                            :to="{ name: 'templates.store', params: {type: 'notification'} }"
                            v-if="authUser.hasAccessTo('templates.store')"
                        >
                            <icon name="bold/add" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <user-selection
                    :multiple="true"
                    :disabled="locked"
                    :filterable="true"
                    :label="$t('automations.actions.sendNotification.to')"
                    :placeholder="$t('automations.actions.sendNotification.to')"
                    :required="true"
                    :value="selectedReceiver"
                    :with-relative="true"
                    :with-guest-group="true"
                    @input="updateSelectedReceiver"
                />
            </div>

            <div v-if="selectedTemplate">
                <div class="flex items-center mt-4" v-if="selectedTemplate.body">
                    <activix-checkbox
                        size="sm"
                        :value="notificationEmail"
                        :disabled="locked"
                        @input="updateEmailNotification"
                    >
                        <span class="font-normal" v-text="$t('automations.actions.sendNotification.email')" />
                    </activix-checkbox>
                    <activix-tooltip :content="$t('automations.actions.sendNotification.emailTooltip')">
                        <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                    </activix-tooltip>
                </div>
                <div class="flex items-center mt-4" v-if="selectedTemplate.excerpt">
                    <activix-checkbox
                        size="sm"
                        :value="notificationPush"
                        :disabled="locked"
                        @input="updatePushNotification"
                    >
                        <span class="font-normal" v-text="$t('automations.actions.sendNotification.push')" />
                    </activix-checkbox>
                    <activix-tooltip :content="$t('automations.actions.sendNotification.pushTooltip')">
                        <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                    </activix-tooltip>
                </div>
                <div class="flex items-center mt-4" v-if="selectedTemplate.excerpt">
                    <activix-checkbox
                        size="sm"
                        :value="notificationScreen"
                        :disabled="locked"
                        @input="updateScreenNotification"
                    >
                        <span class="font-normal" v-text="$t('automations.actions.sendNotification.screen')" />
                    </activix-checkbox>
                    <activix-tooltip :content="$t('automations.actions.sendNotification.screenTooltip')">
                        <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                    </activix-tooltip>
                </div>
            </div>

        </div>

        <div class="flex flex-col w-full lg:w-3/4 mt-4 mr-4" v-if="selectedTemplate">
            <activix-tabs
                tab-width-class-name="w-26"
                :tabs="tabs"
                text-alignment="center"
                v-model="currentLocale"
            />
            <div class="flex flex-col 2xl:flex-row w-full justify-between bg-white py-4 rounded-md rounded-tl-none shadow-lg">
                <div class="flex flex-col w-full px-4" :class="{'2xl:pr-0': notificationPush || notificationScreen}" v-if="notificationEmail">
                    <activix-label :label="$t('templates.preview.email')" class="text-sm lg:text-base" />
                    <div class="panel panel-default | flex-grow mb-0">
                        <div class="panel-heading">
                            <activix-tooltip :content="$t('automations.actions.sendNotification.templateTooltip')">
                                <span v-html="previewSubject" />
                            </activix-tooltip>
                        </div>
                        <div class="panel-body">
                            <span v-html="previewBodyEmail" />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col px-4 2xl:mt-0" :class="{'mt-6': notificationEmail}" v-if="notificationPush || notificationScreen">
                    <div style="width: 380px;">
                        <activix-label :label="$t('templates.preview.notification')" class="text-sm xl:text-base" />
                        <div class="notification-wrapper">
                            <div class="mr-1">
                                <activix-tooltip :content="previewSubject">
                                    <icon class="w-6 h-6 text-grey-600" name="regular/video-game-magic-wand" />
                                </activix-tooltip>
                            </div>
                            <div class="flex-1 px-4 overflow-hidden">
                                <div class="subject | font-bold">
                                    {{ previewSubject }}
                                </div>
                                <activix-tooltip :content="previewBodyNotification">
                                    <div class="subject" v-html="previewBodyNotification" />
                                </activix-tooltip>
                                <div class="subject">
                                    {{ $t('notificationCenter.lead') }} :
                                    <router-link class="link-blue" to="#">
                                        {{ $t('templates.leadName') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="flex flex-col text-right text-sm text-grey-600">
                                <div>{{ creationDate }}</div>
                                <div>{{ creationTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Utils
    import { cloneDeep, get, isEmpty, orderBy, has, uniq, difference } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import EmailCompose from '@/mixins/EmailCompose.js';
    import ActivixLabel from '@/components/elements/ActivixLabel.vue';
    import UserSelection from './CreateTaskEvent/UserSelection.vue';

    // Entities
    import Role from '../../../entities/Role.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';
    import ActivixDate from '../../../value-objects/ActivixDate.js';

    export default {
        name: 'SendNotification',

        components: {
            ActivixLabel,
            UserSelection,
        },

        mixins: [EmailCompose],

        props: {
            action: {
                type: Object,
                required: true,
            },
            disableForEditing: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {
                currentLocale: this.$i18n.locale,
                previewBodyEmail: this.$t('automations.actions.sendNotification.previewMessage'),
                previewBodyNotification: this.$t('automations.actions.sendNotification.previewNotification'),
                previewSubject: `<b>${this.$t('automations.actions.sendNotification.subject')}: </b>`,
                roles: [
                    {
                        key: 'commercial_id',
                        label: this.$t('automations.leadRelatedUser.commercial'),
                        role_id: Role.COMMERCIAL,
                    },
                    {
                        key: 'service_agent_id',
                        label: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                        role_id: Role.SERVICE_AGENT,
                    },
                    {
                        key: 'service_advisor_id',
                        label: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                        role_id: Role.SERVICE_ADVISOR,
                    },
                    {
                        key: 'bdc_user_id',
                        label: this.$t('automations.leadRelatedUser.bdcUser'),
                        role_id: Role.AGENT,
                    },
                    {
                        key: 'user_id',
                        label: this.$t('automations.leadRelatedUser.user'),
                        role_id: Role.ADVISOR,
                    },
                ],
                selectedGroups: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                authUser: 'authUser',
                activeUsers: 'activeUsers',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                filteredContextResponseTemplatesNotification: 'filteredContextResponseTemplatesNotification',
            }),

            locked() {
                return !this.authUser.automation_advanced_access || this.disableForEditing;
            },

            tabs() {
                if (!this.selectedTemplate) {
                    return [];
                }
                return this.selectedTemplate.locales.map(translation => {
                    return {
                        name: translation,
                        label: this.$t(`templates.locales.${translation}.name`),
                    };
                }).sort((a) => (this.contextAccount.locale === 'fr' && a.name === 'fr' ? -1 : 1));
            },

            creationDate() {
                return new ActivixDate(new Date()).toHumanShort(false);
            },

            creationTime() {
                return new ActivixDate(new Date()).toHumanTime();
            },

            notificationEmail() {
                return !!this.selectedTemplate.body;
            },

            notificationPush() {
                return !!this.selectedTemplate.excerpt;
            },

            notificationScreen() {
                return !!this.selectedTemplate.excerpt;
            },

            selectedReceiver() {
                return (this.action.parameters.receiver_id && this.action.parameters.receiver_id.length > 0) ||
                    (this.selectedGroups && this.selectedGroups.length > 0) ? this.action.parameters.receiver_id.concat(this.selectedGroups) : null;
            },

            selectedTemplate() {
                return this.responseTemplatesFiltered.find(
                    template => template.id == this.action.parameters.template.id,
                );
            },

            responseTemplatesFiltered() {
                const templates = this.filteredContextResponseTemplatesNotification
                    .filter(template => {
                        return (
                            template.division_id == 6 &&
                            template.translations.some(translation => translation.locale == this.currentLocale)
                        );
                    })
                    .map(template => {
                        const translation = template.translations.find(trans => trans.locale == this.currentLocale);

                        const fallbackLang = this.currentLocale == 'fr' ? 'en' : 'fr';
                        const fallbackTranslation = template.translations.find(trans => trans.locale == fallbackLang);

                        const title = template.title || translation?.title || fallbackTranslation?.title;

                        return {
                            id: template.id,
                            main: !template.parent,
                            subject: translation.subject,
                            body: translation.body,
                            excerpt: translation.excerpt,
                            title: template.parent
                                ? `[${this.$t('leadXpress.parent')}] ${title}`
                                : title,
                            division_id: template.division_id,
                            otherLocale: !!template.translations.find(trans => trans.locale !== this.currentLocale),
                            locales: template.translations.map(trans => trans.locale),
                        };
                    });

                return orderBy(templates, ['title']);
            },

            isValid() {
                return !!this.action.parameters.notification &&
                    !!this.selectedTemplate &&
                    !!this.selectedReceiver &&
                    this.action.execution.isValid();
            },
        },

        watch: {
            'selectedTemplate.body'(newValue) {
                this.updateEmailNotification(!!newValue);
            },

            'selectedTemplate.excerpt'(newValue) {
                this.updateAction('parameters', {
                    notification: {
                        ...this.action.parameters.notification,
                        push: !!newValue,
                        screen: !!newValue,
                    },
                });
            },

            selectedTemplate() {
                this.updatePreview();
            },

            action(newValue) {
                if (!has(newValue, 'valid')) {
                    this.updateAction('valid', this.isValid);
                }
            },

            isValid: {
                immediate: true,
                handler(newValue) {
                    this.updateAction('valid', newValue);
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['reloadContextAccount']),

            async refreshTemplates() {
                this.$wait.start('fetching.templates');

                await this.reloadContextAccount();

                this.$wait.end('fetching.templates');
            },

            updateSelectedTemplate(value) {
                const template = { ...this.action.parameters.template, id: get(value, 'id', null), title: get(value, 'title', null) };

                this.updateAction('parameters', { template });
            },

            convertAttributesToContent(content, locale) {
                content = this.normalizeTagText(content);

                this.templateAttributes.forEach(category => {
                    category.tags.forEach(tag => {
                        const placeholderContent = tag[`placeholder-${locale}`];
                        const attributeFr = this.normalizeTagText(`{${category.name_fr} - ${tag.text_fr}}`);
                        const attributeEn = this.normalizeTagText(`{${category.name_en} - ${tag.text_en}}`);

                        content = content.split(attributeFr).join(placeholderContent);
                        content = content.split(attributeEn).join(placeholderContent);
                    });
                });

                return content;
            },

            normalizeTagText(content) {
                if (content.includes('&amp;')) {
                    return content.replace(/&amp;/g, '&');
                }

                return content;
            },

            updateSelectedReceiver(newReceiverList) {
                newReceiverList = this.validateRemovedItem(newReceiverList);
                newReceiverList = this.validateAddedItem(newReceiverList);

                const roles = newReceiverList.filter(receiverId => typeof receiverId == 'string' && !receiverId.startsWith('G-'));
                const receiverId = newReceiverList.filter(receiverId => (typeof receiverId !== 'string' || !receiverId.startsWith('G-')));

                this.selectedGroups = newReceiverList.filter(receiverId => typeof receiverId == 'string' && receiverId.startsWith('G-'));
                const groupUsersId = this.findGroupUsers(this.selectedGroups);

                const selectedReceiverIds = uniq(receiverId.concat(groupUsersId));

                this.updateAction('parameters', { to_role: roles.length > 0 ? roles : null, receiver_id: selectedReceiverIds });
            },

            updateEmailNotification(value) {
                this.updateAction('parameters', { notification: { ...this.action.parameters.notification, email: value } });
            },

            updateScreenNotification(value) {
                this.updateAction('parameters', { notification: { ...this.action.parameters.notification, screen: value } });
            },

            updatePushNotification(value) {
                this.updateAction('parameters', { notification: { ...this.action.parameters.notification, push: value } });
            },

            updatePreview() {
                if (!isEmpty(this.selectedTemplate)) {
                    this.previewSubject = this.convertAttributesToContent(this.selectedTemplate.subject, this.currentLocale);
                    this.previewBodyEmail = this.convertAttributesToContent(this.selectedTemplate.body, this.currentLocale);
                    this.previewBodyNotification = this.convertAttributesToContent(this.selectedTemplate.excerpt, this.currentLocale);
                } else {
                    this.previewSubject = this.$t('automations.actions.sendNotification.subject');
                    this.previewBodyEmail = `<b>${this.$t('automations.actions.sendNotification.previewMessage')}</b>`;
                    this.previewBodyNotification = `<b>${this.$t('automations.actions.sendNotification.previewNotification')}</b>`;
                }
            },

            updateAction(key, value) {
                const action = cloneDeep(this.action);

                if (typeof value === 'object') {
                    action[key] = { ...action[key], ...value };
                } else {
                    action[key] = value;
                }

                this.$emit('update:action', action);
            },

            findGroupUsers(groupIds) {
                const guestGroupIds = groupIds.map(group => {
                    return group.split('-').pop();
                });

                const guestGroups = this.contextAccount.guest_groups.filter(guestGroup => guestGroupIds.includes(`${guestGroup.id}`));

                const usersId = [];
                guestGroups.forEach(guestGroup => {
                    usersId.push(this.getUniqueUsersIdToModifyChecked(guestGroup));
                });

                return usersId.flat();
            },

            getUniqueUsersIdToModifyChecked(guestGroup) {
                const usersToUpdateByRoles = guestGroup.roles ? this.activeUsers.filter(user => guestGroup.roles.includes(user.role_id)) : [];
                const usersToUpdateByPosts = guestGroup.posts ? this.activeUsers.filter(user => guestGroup.posts.includes(user.post_id)) : [];
                const usersToUpdateByIds = guestGroup.users ? this.activeUsers.filter(user => guestGroup.users.includes(user.id)) : [];

                const uniqueUsers = uniq(usersToUpdateByRoles.concat(usersToUpdateByPosts).concat(usersToUpdateByIds));

                return uniqueUsers.map(user => user.id);
            },

            getGuestGroupToRemove(users) {
                const guestGroups = this.contextAccount.guest_groups;

                let removedGuestGroups = [];
                guestGroups.forEach(group => {
                    const usersId = group.users;
                    const selectedUsersPerGuestGroup = users.filter(user => usersId.includes(user));
                    if (usersId.length != selectedUsersPerGuestGroup.length) {
                        removedGuestGroups = removedGuestGroups.concat(group);
                    }
                });

                return removedGuestGroups.map(guestGroup => `G-${guestGroup.id}`);
            },

            getCompleteGuestGroup(users) {
                const guestGroups = this.contextAccount.guest_groups;

                let checkedGuestGroups = [];
                guestGroups.forEach(group => {
                    const usersId = group.users;

                    const selectedUsersPerGuestGroup = users.filter(user => usersId.includes(user));

                    if (usersId.length == selectedUsersPerGuestGroup.length) {
                        checkedGuestGroups = checkedGuestGroups.concat(group);
                    }
                });

                return checkedGuestGroups.map(guestGroup => `G-${guestGroup.id}`);
            },

            validateRemovedItem(newReceiverList) {
                let receiverList = newReceiverList;

                const receiverRemoved = difference(this.selectedReceiver, receiverList);
                const groupRemoved = receiverRemoved.find(receiverId => typeof receiverId == 'string' && receiverId.startsWith('G-'));

                if (groupRemoved) {
                    const combinedUsersToUnchecked = this.findGroupUsers([groupRemoved]);
                    receiverList = receiverList.filter(
                        receiver => !combinedUsersToUnchecked.some(user => user == receiver),
                    );
                }

                if (receiverRemoved.length > 0) {
                    const guestGroupsToRemove = this.getGuestGroupToRemove(receiverList);

                    if (guestGroupsToRemove.length) {
                        receiverList = receiverList.filter(
                            receiver => !guestGroupsToRemove.some(group => group == receiver),
                        );
                    }
                }

                return receiverList;
            },

            validateAddedItem(newReceiverList) {
                let receiverList = newReceiverList;

                const receiverAdded = difference(receiverList, this.selectedReceiver);
                const groupAdded = receiverAdded.find(receiverId => typeof receiverId == 'string' && receiverId.startsWith('G-'));

                if (groupAdded) {
                    const combinedUsersToChecked = this.findGroupUsers([groupAdded]);

                    receiverList = uniq(receiverList.concat(combinedUsersToChecked));
                }

                if (receiverAdded.length > 0) {
                    const guestGroupsToAdd = this.getCompleteGuestGroup(receiverList);

                    if (guestGroupsToAdd.length) {
                        receiverList = uniq(receiverList.concat(guestGroupsToAdd));
                    }
                }

                return receiverList;
            },
        },
    };
</script>

<style lang="less">
.notification-wrapper {
@apply relative whitespace-nowrap border border-grey-200 p-4 flex items-center;

    width: 363px;

    .subject {
    @apply truncate text-sm text-grey-600;
    }
}
</style>
