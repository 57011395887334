<template>
    <div class="box">
        <div class="box-header | flex items-center border-b">
            <icon class="mr-2 text-xl text-grey-600" name="regular/video-game-magic-wand" />
            <h4 class="box-title font-normal">
                {{ $t('automations.action') }}
            </h4>
        </div>

        <div class="box-body">
            <div :key="index" v-for="(action, index) in automation.actions">
                <action
                    class="flex mb-6"
                    :automation="automation"
                    :action="action"
                    ref="actions"
                    @update:action="updateAction(index, $event)"
                    @remove="removeAction(index)"
                />
            </div>

            <div class="mt-4">
                <span class="cursor-pointer" @click="addAction">
                    {{ $t('automations.addAction') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    // Components
    import Action from './Action.vue';

    // Entities
    import AutomationAction from '../../entities/AutomationAction.js';

    export default {
        name: 'AutomationActions',

        components: {
            Action,
        },

        inject: ['setAutomationData', 'setAutomationAction'],

        props: {
            automation: {
                required: true,
            },
        },

        computed: {
            isValid() {
                return this.automation.actions.length > 0 && this.automation.actions.every(action => action.valid);
            },
        },

        watch: {
            isValid: {
                immediate: true,
                handler(newValue) {
                    this.$emit('update:is-valid', newValue);
                },
            },
        },

        methods: {
            removeAction(index) {
                this.setAutomationAction(index, null);
            },

            addAction() {
                this.setAutomationAction(this.automation.actions.length, new AutomationAction());
            },

            updateAction(index, values) {
                const action = { ...this.automation.actions[index], ...values };
                this.setAutomationAction(index, action);
            },
        },
    };
</script>
