import Enum from './Enum.js';

export default class LeadVehicleTireType extends Enum {
    static get entries() {
        return {
            summer: 'summer',
            winter: 'winter',
        };
    }

    static get SUMMER() {
        return this.entries.summer;
    }

    static get WINTER() {
        return this.entries.winter;
    }

    static get translationGroup() {
        return 'leadVehicleTireTypes';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
